const informazioni = [
    {
        id: 0,
        sottotitolo:"Piccola nelle dimensioni,grande nella tecnologia.",
        paragrafo: "Compatta e resistente come tutte le Snack&Food Fas, 750 Business è un distributore automatico con un'altezza pari a 170 cm, 6 cassetti e 6 selezioni per ognuno di esso. È equipaggiato inoltre delle principali tecnologie Fas, come: Frigo CO2 slide in-slide out, easy click e Fas Cloud+.",
    },
    {
        id: 1,
        sottotitolo:"La tradizione FAS incontra l'alta tecnologia.",
        paragrafo: "Una macchina di design FAS che porta con sé alcune delle più importanti caratteristiche: Fas Cloud +, Easy Click e il gruppo refrigerante Slide IN Slide OUT.",
    },
    {
        id: 2,
        sottotitolo:"La tradizione FAS incontra l'alta tecnologia.",
        paragrafo: "Una macchina di design FAS che porta con sé alcune delle più importanti caratteristiche: Fas Cloud +, Easy Click e il gruppo refrigerante Slide IN Slide OUT.",
    },
]

export default informazioni;