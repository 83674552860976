import React, { useState, useRef } from "react";
import './contattaci.css';
import emailjs from '@emailjs/browser';

function Contattaci() {

    const [mostraContattaci, setMostraContattaci] = useState(false);
    const [mostraMessaggio, setMostraMessaggio] = useState(false);
    const [messaggio, setMessaggio] = useState("");
    const [statoMappa, setStatoMappa] = useState(false);

    const form = useRef();

    const inviaEmail = (e) => {
        e.preventDefault();
        // Il primo id è il service id, il secondo è id del template, l'ultimo è la public key dell'API di emailjs
        emailjs.sendForm('service_z14nwyl', 'template_y8j2cs6', form.current, 'laA9_iXXZbWNgoxNh')
        .then((result) => {
            console.log(result.text);
            setMessaggio("Email inviata con successo!");
            setMostraMessaggio(true);
            setTimeout(() => {
                setMostraMessaggio(false);
            }, 5000); 
        }, (error) => {
            console.log(error.text);
            setMessaggio("Errore durante l'invio dell'email.");
            setMostraMessaggio(true);
            setTimeout(() => {
                setMostraMessaggio(false);
            }, 5000); 
        });
        form.current.reset();
    };

    const MappaCaricata = () => {
        setStatoMappa(true);
    };
    
    return(
        <>
        {!statoMappa ? (
            <>
                <div className="loading">
                    <p>Caricamento . . . </p>
                </div>
            </>
        ) : (
           <>
        <div>
            <div style={{marginLeft: "40%"}}>
                {mostraMessaggio && (
                    <div className="messaggio">{messaggio}</div>
                )}
            </div>
        <div className="bloccoContact">
            <div className="popup__text">
                <h1>Contattaci</h1>
                <p>Se stai cercando un partner affidabile nel settore del Vending, Noi siamo la scelta giusta per te. Contattaci oggi per scoprire come possiamo migliorare l'esperienza di Vending nel tuo spazio!</p>
                <div className="mappa">
                    <iframe title="myframe" 
                    width="100%"        
                    height="400" 
                    frameborder="0" 
                    marginheight="0" 
                    marginwidth="0" 
                    src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=45.421427,%209.429467+(SnackTime)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    onLoad={MappaCaricata}
                    >
                        <a href="https://www.gps.ie/">gps vehicle tracker</a>
                    </iframe>
                </div>
            </div>
            <div className="sfondoContact">
                <form ref={form} onSubmit={inviaEmail}>
                    <div>
                        <input type="text" id="nome" name="nome" placeholder="Inserire il nome"/>
                    </div>
                    <div>
                        <input type="text" id="cognome" name="cognome" placeholder="Inserire il cognome"/>
                    </div>
                    <div>
                        <input type="text" id="email" name="email" placeholder="Inserire email"/>
                    </div>
                    <div>
                        <input type="text" id="telefono" name="telefono" placeholder="Inserire il numero di telefono"/>
                    </div>
                    <div>
                        <textarea id="message" name="message" placeholder="Inserire il messaggio" rows="4" cols="50"/>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
            
            <button className="contact" type="button" onClick={() => setMostraContattaci(true)} style={{marginBottom: "10%"}}>Contattaci</button>
            {mostraContattaci && (
                <div className="popup">
                    <div className="popup-inner">
                        <div className="popup__text"></div>
                        <div className="popup__form">
                            <form ref={form} onSubmit={inviaEmail}>
                                <div>
                                    <input type="text" id="nome" name="nome" placeholder="Nome"/>
                                </div>
                                <div>
                                    <input type="text" id="cognome" name="cognome" placeholder="Cognome"/>
                                </div>
                                <div>
                                    <input type="text" id="email" name="email" placeholder="Email"/>
                                </div>
                                <div>
                                    <input type="text" id="telefono" name="telefono" placeholder="Numero di telefono"/>
                                </div>
                                <div>
                                    <textarea id="message" name="message" placeholder="Messaggio"/>
                                </div>
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                        <button className="popup__close" onClick={() => setMostraContattaci(false)}>X</button>
                    </div>
                </div>
            )}
        </div>
        </div>
           </>
        )}
                             
        <div className="mappa2">
            <iframe title="myframe" 
                    src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=45.421427,%209.429467+(SnackTime)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    onLoad={MappaCaricata}
        >
            </iframe>
        </div>       

        </>
    );    
}

export default Contattaci;
