const informazioni = [
    {
        id:0,
        titolo: "AGILY L SMART",
        sottotitolo: "SEI PADRONE DEL CAMBIAMENTO",
        paragrafo: "Un'offerta in continuo movimento per restare al passo con le evoluzioni del mercato: Agily corre insieme a te verso il futuro.",
        paragrafo2: "Nel vending saper cambiare con il giusto tempismo e intercettare le nuove tendenze di consumo è il segreto per restare sulla breccia. Con Agily ti adatti velocemente a qualsiasi novità perché puoi sempre aggiornare la tua soluzione aggiungendo golosità al menù, offrendo maggiore scelta all’utente, rendendo più accattivante l’interazione con la macchina. Scatta verso la prossima opportunità, conquista i tuoi clienti con nuove proposte e continua a far crescere la tua attività: con Agily sei tu il padrone del cambiamento.",
    },
    {
        id:1,
        titolo: "AGILY L TOUCH 21''",
        sottotitolo: "SEI PADRONE DEL CAMBIAMENTO",
        paragrafo: "Un'offerta in continuo movimento per restare al passo con le evoluzioni del mercato: Agily corre insieme a te verso il futuro.",
        paragrafo2: "Nel vending saper cambiare con il giusto tempismo e intercettare le nuove tendenze di consumo è il segreto per restare sulla breccia. Con Agily ti adatti velocemente a qualsiasi novità perché puoi sempre aggiornare la tua soluzione aggiungendo golosità al menù, offrendo maggiore scelta all’utente, rendendo più accattivante l’interazione con la macchina. Scatta verso la prossima opportunità, conquista i tuoi clienti con nuove proposte e continua a far crescere la tua attività: con Agily sei tu il padrone del cambiamento.",
    },
    {
        id:2,
        titolo: "AGILY M SMART",
        sottotitolo: "AGILITÀ: IL SEGRETO DEL TUO SUCCESSO, OGGI E DOMANI",
        paragrafo: "La tua macchina ti calza a pennello? Per forza, è Agily e si adatta a tutte le tue necessità!",
        paragrafo2:"Se cerchi più compattezza puoi scegliere Agily M disponibile sempre in versione Smart o Touch. Grazie all'eccezionale modularità di Agily, puoi impostare la macchina secondo i tuoi obiettivi e, naturalmente, cambiarla in corsa, come e quando vuoi: puoi proporre due varietà di caffè e di zuccheri, arrivare fino a dieci scatole di prodotti solubili, scegliere il kit secondo bicchiere per offrire più formati di bevande oppure il kit topping dispenser per menù ancora più golosi.",
    },
    {
        id:3,
        titolo: "AGILY M TOUCH 21''",
        sottotitolo: "AGILITÀ: IL SEGRETO DEL TUO SUCCESSO, OGGI E DOMANI",
        paragrafo: "La tua macchina ti calza a pennello? Per forza, è Agily e si adatta a tutte le tue necessità!",
        paragrafo2:"Se cerchi più compattezza puoi scegliere Agily M disponibile sempre in versione Smart o Touch. Grazie all'eccezionale modularità di Agily, puoi impostare la macchina secondo i tuoi obiettivi e, naturalmente, cambiarla in corsa, come e quando vuoi: puoi proporre due varietà di caffè e di zuccheri, arrivare fino a dieci scatole di prodotti solubili, scegliere il kit secondo bicchiere per offrire più formati di bevande oppure il kit topping dispenser per menù ancora più golosi.",
    },
    {
        id:4,
        titolo: "INTUITY PLUS TOUCH 32''",
        sottotitolo: "PER CHI VUOLE TUTTO",
        paragrafo: "Intuity trasforma l'esperienza d'acquisto in una scoperta continua moltiplicando le possibilità di consumo praticamente all'infinito.",
        paragrafo2: "Capacità fuori misura, interazione avanzata, gusto in abbondanza, personalizzazione delle bevande senza limiti, stile unico. Intuity ha davvero tutto quello che ti serve per soddisfare qualsiasi desiderio dei tuoi clienti e per crearne sempre di nuovi stimolando la loro immaginazione. Ma ha anche tutto quello vorresti tu, perché con le sue caratteristiche rivoluzionare rende più semplice e più produttivo il tuo lavoro e ti permette di evolvere la tua offerta in qualsiasi momento. Porta la tua attività nel vending del futuro: ti basta l'intuizione vincente, al resto ci pensa Intuity.",
    },
    {
        id:5,
        titolo: "INTUITY TOUCH 21”",
        sottotitolo: "OFFRI IL MEGLIO SE VUOI OTTENERE IL MASSIMO OGNI GIORNO",
        paragrafo: "Intuity cambia, cresce, evolve insieme a te: per questo è un investimento vincente. Scegli l'interfaccia touch che preferisci, da 21” a 32” per un'esperienza più immersiva, e poi aggiungi tutto quello che ti serve per moltiplicare le possibilità di consumo.",
        paragrafo2: "Puoi raddoppiare la proposta di caffè e di zuccheri, arrivare fino a dieci scatole di prodotti solubili, garantire una perfetta conservazione degli aromi grazie alla campana caffè sottovuoto. Senza dimenticare che di serie hai già due topping dispenser, due colonne per bicchieri di dimensioni diverse e due palettiere. Non ti basta? Vuoi ancora di più? Abbina Intuity a una macchina snack e bevande fredde come Flessy L o XL per un'offerta straordinariamente ampia e variegata.",
    },
    {
        id:6,
        titolo: "LEI250 EASY/SMART/TOUCH 15''",
        sottotitolo: "LEI250, IL CAFFÈ IN TUTTE LE SUE QUALITÀ.",
        paragrafo: "LEI250, distributore automatico con capacità di 250 bicchieri, versioni caffè in grani e caffè solubile.",
    },
    {
        id:7,
        titolo: "LEI2GO TOUCH 32''",
        sottotitolo: "LA MACCHINA AD ALTA CAPACITÀ PROGETTATA E REALIZZATA PER SERVIRE AL MEGLIO TUTTE LE AREE PUBBLICHE.",
        paragrafo: "LEI2GO TOUCH 32” è il distributore automatico all-in-one di bevande calde e fredde, personalizzabili con prodotti di alta qualità e accompagnabili con gustosi mini snack. Grazie ad una capacità senza paragoni e al suo sistema di protezione anti vandalico, LEI2GO TOUCH 32” rappresenta la macchina ideale per rispondere al meglio alle esigenze dei consumatori in transito nei luoghi pubblici più affollati.",
    },
    {
        id:8,
        titolo: "LEI301 EVO EASY/SMART/TOUCH 15''",
        sottotitolo: "LA MODULARITÀ TROVA LA SUA MASSIMA ESPRESSIONE CON LEI301 EVO",
        paragrafo: "Con LEI301 EVO costruisci la tua offerta in base ai desideri dell'utente finale. Il tuo cliente vorrebbe gustare la sua bevanda preferita anche in formato XL? Con il kit secondo bicchiere puoi accontentarlo. Oppure preferisce tante nuove ricette golose? Ecco il kit doppio topping per soddisfarlo. E abbinata ad ARIA S EVO, il distributore automatico a spirali per la vendita di snack, lattine e bottiglie, LEI300 EVO diventa una soluzione Combi Hot & Snack ideale per aree di piccole e medie dimensioni",
    },
    {
        id:9,
        titolo: "LEI400",
        sottotitolo: "IL MIGLIOR RAPPORTO QUALITÀ-PREZZO CHE RISPONDE AI REQUISITI ESSENZIALI.",
        paragrafo: "LEI400, distributore automatico per bevande calde con capacità di 400 bicchieri.",
    },
    {
        id:10,
        titolo: "LEI400 EASY/SMART/TOUCH 21''",
        sottotitolo: "LA TECNOLOGIA EROGA CONVENIENZA.",
        paragrafo: "LEI400, distributore automatico per bevande calde con capacità di 400 bicchieri. Progettato per poter cambiare facilmente la tastiera di selezione utente e adattarsi a diverse locazioni.",
    },
    {
        id:11,
        titolo: "LEI600",
        sottotitolo: "UN MODELLO CHE COMBINA QUALITÀ E SEMPLICITÀ PER UN'OFFERTA DI PRODOTTO ASSORTITA.",
        paragrafo: "LEI600, distributore automatico per bevande calde con capacità di 600 bicchieri..",
    },
    {
        id:12,
        titolo: "LEI600 EASY/SMART/TOUCH 21''",
        sottotitolo: "ECONOMIA E TECNOLOGIA NON SONO PIÙ INCOMPATIBILI.",
        paragrafo: "LEI600, distributore automatico per bevande calde con capacità di 600 bicchieri. Progettato per poter cambiare facilmente la tastiera di selezione utente e adattarsi a diverse locazioni.",
    },
    {
        id:13,
        titolo: "LEI600 PLUS 2CUPS TOUCH 21'' + MODULO COFFEE TO GO",
        sottotitolo: "LA SOLUZIONE IDEALE PER PERSONALIZZARE LE BEVANDE E REGALARSI UNA PAUSA ANCORA PIÙ GUSTOSA CON I MINI-SNACK DEL MODULO COFFE TO GO.",
        paragrafo: "LEI600 PLUS 2CUPS, distributore automatico di bevande calde e fredde, abbina qualità e convenienza. Touchscreen di ultima generazione, doppia torretta di bicchieri per una capacità che può raggiungere i 1000 bicchieri e totale libertà di personalizzazione rendono LEI600 PLUS 2CUPS l'investimento più intelligente per chi cerca l'eccellenza più accessibile.",
    },
    {
        id:14,
        titolo: "LEI700 PLUS 2CUPS TOUCH 32'' + MODULO COFFEE TO GO",
        paragrafo: "LEI700 PLUS 2CUPS TOUCH 32”, distributore automatico di bevande calde e fredde con doppia torretta bicchieri, con capacità di 1000 bicchieri. Collegata al modulo Coffee To Go, è sicuramente l'unica macchina che può incontrare tutti i gusti.",
    },   
]

export default informazioni;