import React,{useState} from "react";
import spunta from "./imagehomepage/spunta.png";
import biliardino from "./imagehomepage/biliardino.jpg"
import qualita from "./imagehomepage/altaqualita.png"
import "./Home.css";
import icona from "./imagehomepage/macchinettaIcona.jpg";

function Home() {

    const [frecce, setFrecce] = useState(false);
    function closeBanner() {
        document.querySelector('.privacy-banner').style.display = 'none';
      }
    return (
        <>
        <div class="privacy-banner">
            <p>La tua privacy è importante per noi. I dati inseriti dai clienti non vengono distribuiti a terzi, ma rimangono nell'azienda ai fini stabiliti.</p>
            <button onClick={closeBanner} className="bottoneprivacy">Accetto</button>
        </div>
        <div>
            <h1>Il Vending del Futuro: Gusto, Qualità, Innovazione e Affidabilità</h1>
            <h2>Benvenuti a Snack Time Distributori Srl - la scelta intelligente per il tuo break!</h2>
            <div className="effetto">
                <div className="blocco">
                    <div className="spazioHome">
                        <img src={spunta} alt="nome" className="immmaginiHome"/>
                    </div>
                    <div className="divTesto">
                        <p className="parolaChiave">Affidabilità</p>
                        <p className="test">
                            L'affidabilità è un concetto chiave della nostra azienda nel settore dei distributori automatici di snack e 
                            bevande. La nostra priorità assoluta è garantire ai nostri clienti un servizio impeccabile, caratterizzato da consegne puntuali,
                            prodotti freschi e una manutenzione costante dei nostri distributori.
                        </p>
                    </div>
                </div>
                <div className="blocco">
                    <div className="divTesto3">
                        <p className="parolaChiave">Qualità</p>
                        <p className="test">
                            La qualità è il pilastro fondamentale su cui si basa la nostra azienda nel settore dei distributori automatici 
                            di snack e bevande. Ci impegniamo costantemente a garantire ai nostri clienti prodotti di eccellenza, selezionati con cura e 
                            provenienti dai migliori fornitori. Ogni passo del nostro processo, dalla selezione dei prodotti alla loro consegna, è 
                            guidato dalla ricerca della massima qualità. Ogni singolo articolo presente nei nostri distributori è sottoposto a rigorosi 
                            controlli per garantire freschezza, gusto e valore nutrizionale.
                        </p>
                    </div>
                    <div className="spazioHome">
                        <img src={qualita} alt="nome" className="immmaginiHome"/>
                    </div>
                    <div className="divTesto2">
                        <p className="parolaChiave">Qualità</p>
                        <p className="test">
                            La qualità è il pilastro fondamentale su cui si basa la nostra azienda nel settore dei distributori automatici di snack e 
                            bevande. Ci impegniamo costantemente a garantire ai nostri clienti prodotti di eccellenza, selezionati con cura e provenienti 
                            dai migliori fornitori. Ogni passo del nostro processo, dalla selezione dei prodotti alla loro consegna, è guidato dalla ricerca
                            della massima qualità. Ogni singolo articolo presente nei nostri distributori è sottoposto a rigorosi controlli per garantire 
                            freschezza, gusto e valore nutrizionale.</p>
                    </div>
               </div>
            </div>
                <div className="blocco">
                    <div className="spazioHome">
                    <svg className="svg" xmlns="http://www.w3.org/2000/svg" height="60" viewBox="0 -940 960 960" width="45">
                        <path d="M318-150h324v-117.065Q642-334 594.218-382q-47.781-48-114.262-48t-114.219 48Q318-334 318-267.065V-150Zm162.044-380q66.481 0 114.219-48.5Q642-627 642-693.935V-810H318v116.065Q318-627 365.782-578.5T480.044-530ZM139-59v-91h88v-117q0-68.726 36-125.363T361-480q-62-31-98-87.955Q227-624.911 227-694v-116h-88v-92h683v92h-88v116q0 69.089-36 126.045Q662-511 600-480q62 31 98 87.637T734-267v117h88v91H139Z"/>
                    </svg>
                    </div>
                    <div className="divTesto">
                        <p className="parolaChiave">Tempestività</p>
                        <p className="test">
                            La tempestività è un principio cardine che guida le nostre operazioni nel settore dei distributori automatici 
                            di snack e bevande. Ci impegniamo incessantemente per assicurare che i nostri servizi e le nostre consegne siano sempre 
                            puntuali e affidabili. Sappiamo quanto sia cruciale per i nostri clienti ricevere i prodotti richiesti senza ritardi, e per 
                            questo mettiamo in atto sistemi di gestione efficienti e processi ottimizzati per garantire una tempestiva erogazione dei 
                            nostri servizi. La nostra organizzazione è strutturata per rispondere prontamente alle richieste dei clienti e per affrontare 
                            qualsiasi imprevisto in modo rapido ed efficace
                        </p>
                    </div>    
                </div>
        </div>

        <div>
            <div onMouseEnter={() => setFrecce(true)} onMouseLeave={() => setFrecce(false)}>
                <div>
                    <p className="parolaChiave2">Le caratteristiche delle nostre macchinette</p>
                </div>
                <div
                onMouseEnter={() => setFrecce(true)}
                onMouseLeave={() => setFrecce(false)}
                style={{ position: "relative"}}
                >
                <img src={icona} alt="nome" className="immmagineTelemetria" />
                {frecce && (
                    <div>
                        <div>
                            <div className="fp1">
                                <p>Migliore qualità dei prodotti snack e bevande all'interno dei nostri distributori</p>
                            </div>
                            <div className="freccia1"></div>
                        </div>
                        <div>
                            <div className="fp2">
                                <p>Tracking di ogni nostro distributore grazie alla telemetria</p>
                            </div>
                            <div className="freccia2"></div>
                        </div>
                        <div>
                            <div className="fp3">
                                <p>Assistenza per ogni tipo di problema</p>
                            </div>
                            <div className="freccia3"></div>
                        </div>
                        <div>
                            <div className="fp4">
                                <p>Sicurezza dei nostri distributori per un maggior servizio al cliente</p>
                            </div>
                            <div className="freccia4"></div>
                        </div>
                    </div>
                )}
                </div>
                <div className="caratteristiche">
                    <div className="divCara">
                            <p className="paraCara">Migliore qualità dei prodotti snack e bevande all'interno dei nostri distributori</p>
                            <p className="paraCara">Tracking di ogni nostro distributore grazie alla telemetria</p>
                            <p className="paraCara"> Assistenza per ogni tipo di problema</p>
                            <p className="paraCara">Sicurezza dei nostri distributori per un maggior servizio al cliente</p>

                    </div>
                </div>
            </div>
        </div>
        
        <div className="marginTele">
            <h1 className="titoloTele">Telemetria</h1>
            <div className="spazioArea">
                <img src={icona} alt="telemetria"  className="imagetele"/>
                <p className="testoTele">
                    Un importante servizio che offriamo è la telemetria, un sistema di monitoraggio remoto che consente di raccogliere dati in tempo reale da dispositivi o sensori remoti e trasmetterli al nostro centro di controllo. 
                    Questo strumento essenziale ci fornisce informazioni cruciali su parametri quali temperatura, pressione, velocità e posizione, consentendo un monitoraggio accurato e tempestivo su ogni nostro singolo distributore in
                    modo da garantire una maggiore sicurezza e affidabilità.
                </p>
            </div>
        </div>
        <div>
            <div>
                <h1 className="titoloTele">Area Break</h1>
            </div>
            <div className="spazioArea">
                <p  className="testobreak">
                    Per i nostri stimati clienti che gestiscono imprese con un numero di dipendenti ampio, offriamo un'opportunità e
                    sclusiva per arricchire gli ambienti di lavoro con un tocco di svago e intrattenimento. Come segno di apprezzamento per 
                    la vostra fiducia e il vostro impegno continuo, abbiamo il piacere di offrire, in aggiunta al programma di comodato d'uso 
                    di macchinette, un biliardino da posizionare nella sala break. Questa piccola aggiunta non solo migliorerà l'ambiente lavorativo, 
                    ma favorirà anche momenti di distensione e collaborazione tra i vostri dipendenti, promuovendo un clima di lavoro positivo e motivante
                </p>
                <div className="biliardinoSpazio">
                    <img className="imagebili" src={biliardino} alt="biliardino" />
                </div>
                <p  className="testobreak2">
                    Per i nostri stimati clienti che gestiscono imprese con un numero di dipendenti ampio, offriamo un'opportunità e
                    sclusiva per arricchire gli ambienti di lavoro con un tocco di svago e intrattenimento. Come segno di apprezzamento per 
                    la vostra fiducia e il vostro impegno continuo, abbiamo il piacere di offrire, in aggiunta al programma di comodato d'uso 
                    di macchinette, un biliardino da posizionare nella sala break. Questa piccola aggiunta non solo migliorerà l'ambiente lavorativo, 
                    ma favorirà anche momenti di distensione e collaborazione tra i vostri dipendenti, promuovendo un clima di lavoro positivo e motivante
                </p>
            </div>
        </div>
        
        </>
    );
}

export default Home;