import AriaEvoM from "../macchinette/imagemacchinetteBianchiFas/Aria_L_EVO_master.jpg";
import AriaEvoS from "../macchinette/imagemacchinetteBianchiFas/Aria_L_EVO_slave.jpg";
import AriaMSlave from "../macchinette/imagemacchinetteBianchiFas/Aria_M_slave.jpg";
import AriaS from "../macchinette/imagemacchinetteBianchiFas/Aria_S_dyb.jpg";
import FlessyLM from "../macchinette/imagemacchinetteBianchiFas/flessy_L_master.jpg";
import FlessyLS from "../macchinette/imagemacchinetteBianchiFas/flessy_L_slave.jpg";
import FlessyMM from "../macchinette/imagemacchinetteBianchiFas/flessy_M_master.jpg";
import FlessyMS from "../macchinette/imagemacchinetteBianchiFas/flessy_M_slave.jpg";
import FlessyXLM from "../macchinette/imagemacchinetteBianchiFas/flessy_XL_master.jpg";

const prodottisnack = [
    {
        id: 0,
        nome: "Aria L Evo Master",
        img: AriaEvoM,
        rating: 4
    },
    {
        id: 1,
        nome: "Aria L Evo Slave",
        img: AriaEvoS,
        rating: 4
    },
    {
        id: 2,
        nome: "Aria M Slave",
        img: AriaMSlave,
        rating: 3
    },
    {
        id: 3,
        nome: "Aria S Evo Slave",
        img: AriaS,
        rating: 4
    },
    {
        id: 4,
        nome: "Flessy L Master",
        img: FlessyLM,
        rating: 4
    },
    {
        id: 5,
        nome: "Flessy L Slave",
        img: FlessyLS,
        rating: 3
    },
    {
        id: 6,
        nome: "Flessy M Master",
        img: FlessyMM,
        rating: 4
    },
    {
        id: 7,
        nome: "Flessy M Slave",
        img: FlessyMS,
        rating: 4
    },
    {
        id: 8,
        nome: "Flessy XL Master",
        img: FlessyXLM,
        rating: 3
    },
];

export default prodottisnack;