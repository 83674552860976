const specificesnack = [
    {
        id: 0,
        nome: "Festival",
        interfaccia: "Selezione diretta",
        numero_selezioni:"10",
        protocollo: "Executive - MBD",
        altezza:"1830 mm",
        larghezza:"850 mm",
        profondità: "895 mm",
        profondità_porta_aperta: "1630 mm",
        peso: "Circa 348 kg",
        voltaggio: "230 V",
        frequenza: "50 Hz",
    },
    {
        id: 1,
        nome: "MiniSnakky",
        numero_selezioni:"fino a 24",
        protocollo: "Come macchina master",
        altezza:"1080 mm",
        larghezza:"600 mm",
        profondità: "935 mm",
        profondità_porta_aperta: "1450 mm",
        peso: "Circa 130 kg",
        voltaggio: "230 V",
        consumo: "150 W",
        frequenza: "50 Hz",
        numeroVassoi: "Massimo 4 Vassoi",
        numeroMaxVassoi: "4",
    },
    {
        id: 2,
        nome: "Orchestra",
        interfaccia: "Selezione numerica",
        numero_selezioni:"53",
        protocollo: "Execeutive - MDB",
        altezza:"1830 mm",
        larghezza:"900 mm",
        profondità: "790 mm",
        profondità_porta_aperta: "1495 mm",
        peso: "300 kg",
        voltaggio: "630 V",
        frequenza: "50 Hz",
        configurazione: "Snack+Food+Cans&Bottles/Snack+Food/Food/Cans&Bottles",
        numeroVassoi: "Max 7",
        numeroMaxVassoi: "8",
        ascensore_prodotti:"Softvend",
        Temperatura:"Stratificata",
        capacitàProdotto: "466",
    },
    {
        id: 3,
        nome: "orchestra Touch",
    },
    {
        id: 4,
        nome: "Soprano",
        interfaccia: "tastiera capacitiva e ampio display",
        numeroMaxVassoi: "7",
    },
    {
        id: 5,
        nome: "Swing",
        interfaccia: "Selezione numerica",
        numeroVassoi: "Max 7",
    },
    {
        id: 6,
        nome: "Swing SL",
    },
    {
        id: 7,
        nome: "Twist",
        interfaccia: "Selezione numerica",
        numero_selezioni:"36",
        protocollo: "Executive - MDB ",
        altezza:"1830 mm",
        larghezza:"746 mm",
        profondità: "874 mm",
        profondità_porta_aperta: "1358 mm",
        peso: "Circa 225 kg",
        voltaggio: "230 V",
        consumo: "200 W",
        frequenza: "50Hz",
        configurazione: "Food/Snack+Cans&Bottles",
        numeroVassoi: "7",
        numeroMaxVassoi: "7",
        Temperatura:"Stratificata",
        capacitàProdotto: "302",
    },
    {
        id: 8,
        nome: "Vivace",
    },
    {
        id: 9,
        nome: "Greeny Touch",
        interfaccia: "display 7''",
        altezza:"1830 mm",
        larghezza:"405 mm",
        profondità: "540 mm",
        capacita: "300 bottiglie di plasticha vuote",
    },
]

export default specificesnack;