const informazioni = [
    {
        id: 0,
        titolo: "Fas Mia",
        paragrafo: "Design accattivante e ampia scelta di prodotti: Fas Mia è il distributore automatico di bevande calde con una capacità massima di 500 bicchieri e un tablet da 13.3'' con menù ad icone.",
    },
    {
        id: 1,
        titolo: "Fas Lydia",
        paragrafo: "Fas Lydia, il distributore automatico di bevande calde capiente e molteplice. Può contenere fino 500 bicchieri, 7 contenitori per prodotti solubili, mono o doppia campana caffè, interfaccia touch da 13.3’’ e programmazione in locale o remoto con Fas Cloud+. Disponibile in doppia colorazione, grigio o grafite.",
    },
    {
        id: 2,
        titolo: "Fas Victoria",
        sottotitolo: "Il meglio di tecnologia e design",
        paragrafo: "Distributore automatico di bevande calde con una capacità massima di 780 bicchieri, 7 contenitori per prodotti solubili, mono o doppia campana caffè, design accattivante color grafite, interfaccia utente touch da 21.5″ e programmazione icone in locale o da remoto con Fas Cloud+.",
    },
]

export default informazioni;