import React from "react";
import elencobevandeVendo from "../../dati/dativendobevande.js";
import ListaBevande from "./listabevandenecta.jsx"

function BevandeVendo() {
    return(
        <div className="listoneBevande">
            {
                elencobevandeVendo.map(elencobevandeVendo => (
                    <ListaBevande
                        id={elencobevandeVendo.id}
                        img={elencobevandeVendo.img}
                        nome={elencobevandeVendo.nome}
                        rating={elencobevandeVendo.rating}
                    />  
                ))
            }
        </div>
    );
}

export default BevandeVendo;