const informazioni = [
    {
        id: 0,
        Titolo: "Brio",
        sottotitolo: "La table TOP che stavi aspettando",
        paragrafo: "Brio unisce le migliori tecnologie di preparazione del caffè alle innovazioni Necta più attuali. Il risultato è semplicemente entusiasmante.",
        paragrafo2: "L'utente finale migliora la sua esperienza con una vasta scelta di bevande di alta qualità, un processo di selezione e pagamento sicuro tramite smartphone o tastiera capacitiva. Gli operatori possono contare su una nuova macchina dalla tecnologia affidabile, in grado di offrire connettività integrata opzionale che permette la gestione della macchina da remoto",
        paragrafo3: "Con Brio si aprono nuove opportunità di business: grazie al suo design elegante e al suo ingombro limitato, questo modello supera i confini delle tradizionali locazioni del vending e rappresenta la soluzione ideale per moltissimi ambienti e attività.",
        paragrafo4: "Brio: tutta nuova, tutta digital, tutta TOP.",
    },
    {
        id: 1,
        Titolo: "Brio Touch",
        sottotitolo: "la digital table TOP che stavi aspettando",
        paragrafo:"Brio Touch unisce le migliori tecnologie di preparazione del caffè alle performance elettroniche più attuali. Il risultato è semplicemente entusiasmante.",
        paragrafo2:"L'utente finale migliora la sua esperienza con una vasta scelta di bevande di alta qualità, un processo di selezione e pagamento sicuro tramite smartphone. Gli operatori possono contare su una nuova macchina dalla tecnologia affidabile, in grado di offrire soluzioni digitali come touch screen full HD, connettività integrata che permette la gestione della macchina da remoto.",
        paragrafo3: "Con Brio Touch si aprono nuove opportunità di business: la navigazione tra le categorie di prodotti consente di creare menù e promozioni personalizzate e offrire prodotti Snack & Food in upselling. Grazie al suo design elegante e al suo ingombro limitato, questo modello supera i confini delle tradizionali locazioni del vending e rappresenta la soluzione ideale per moltissimi ambienti e attività.",
        paragrafo4: "Brio Touch: tutta nuova, tutta digital, tutta Brio.",
    },
    {
        id: 2,
        Titolo: "Concerto",
        sottotitolo: "Armonia di design e performance",
        paragrafo:"Concerto è il modello della gamma Necta che, grazie alle dimensioni compatte e alla vasta scelta di bevande, rappresenta la soluzione ideale per le medie locazioni o per gli ambienti dove gli spazi dedicati alla pausa sono limitati. Concerto è un distributore dal design elegante e raffinato, le performance tecniche sono all'altezza dei distributori alto di gamma; grazie al gruppo espresso Z4000, la scelta è molto ampia e la qualità delle bevande è sempre al top. Concerto è un punto di riferimento per il proprio segmento di mercato.",
    },
    {
        id: 3,
        Titolo: "Concerto Touch",
        paragrafo:"Concerto Touch porta le migliori innovazioni elettroniche e meccaniche nel segmento dei distributori di altezza 1,70 mt.: tecnologie premium, massima semplicità di gestione e migliore esperienza utente in un tocco! Lo schermo full hd da 13,3” permette di ampliare il menù offerto e le informazioni disponibili per il consumatore rispetto a un distributore a tastiera tradizionale. Con Concerto Touch gli operatori beneficeranno di una straordinaria semplicità di gestione grazie a un'elettronica di ultima generazione. Concerto Touch è un vending più evoluto alla portata di tutti.",
    },
    {
        id: 4,
        Titolo: "Maestro",
        sottotitolo: "Maestro, un tasto per ogni gusto",
        paragrafo:"Elegantissimo, con i profili esterni in alluminio anodizzato nero, la porta nera con cabinet grigio scuro e un'ampia tastiera: si presenta così Maestro, il distributore che completa l'offerta di Necta per le locazioni più esigenti.",
        paragrafo2: "Performance sempre perfette, una vastissima scelta di bevande e una qualità al top: ecco le caratteristiche salienti di questo distributore.",
        paragrafo3: "Da un tè infuso a regola d'arte a un cremoso caffè espresso, l'utenza finale dispone di ben 22 tasti di scelta, per poter gustare il prodotto più adatto alle proprie esigenze.",
        paragrafo4: "Con Maestro hai tutta la varietà e la qualità che desideri, a portata di tasto.",
    },
    {
        id: 5,
        Titolo: "Maestro Touch",
        sottotitolo: "Necta stupisce il mercato con un colpo da Maestro",
        paragrafo:"Elegantissimo, con i profili esterni in alluminio anodizzato nero, la porta nera con cabinet grigio scuro e un touch-screen tutto vetro, senza cornice: si presenta così Maestro Touch, il nuovo distributore al top della gamma Necta.",
        paragrafo2: "Le novità non riguardano solo l'estetica, ma si concentrano in performance sempre più all'avanguardia e su una grande  personalizzazione dell'offerta.",
        paragrafo3: "Il pubblico dispone di un alto grado di personalizzazione delle bevande, con la possibilità di consultare informazioni nutrizionali e cercare il prodotto più adatto alle proprie esigenze, grazie alla funzione filtro per la ricerca avanzata",
        paragrafo4: "Tutte le innovazioni tecniche ed estetiche portano a una user experience entusiasmante: con Maestro Touch la pausa sale di livello,",
    },
    {
        id: 6,
        Titolo: "Opera",
        sottotitolo: "Opera: superiore per prestazioni e qualità",
        paragrafo:"Questo gioiello della gamma Necta garantisce un eccellente rapporto prezzo/prestazione. Opera è un concentrato di tecnologia che racchiude le migliori performance oggi disponibili per la fascia intermedia del mercato free standing ed offre ampia scelta e ottima qualità delle bevande erogate. Opera: la soluzione vincente per aumentare i margini e conquistare nuovi clienti.",
       },
    {
        id: 7,
        Titolo: "Opera Touch",
        sottotitolo: "Opera Touch, un'attrazione irresistibile",
        paragrafo:"La migliore tecnologia elettronica e il miglior layout meccanico, al servizio della massima semplicità di gestione e della migliore esperienza utente: questo è Opera Touch. Lo schermo full hd da 13,3” permette di ampliare sensibilmente l'offerta e le informazioni disponibili per il consumatore rispetto a un distributore tradizionale. Opera Touch offre agli operatori una straordinaria semplicità di gestione grazie a un'elettronica di ultima generazione: programmare e personalizzare le funzioni del distributore velocemente non è più un problema. Opera Touch conquista tutti con un'idea più evoluta della distribuzione automatica.",
    },
    {
        id: 8,
        Titolo: "Solista",
        sottotitolo: "Il table top di riferimento",
        paragrafo:"Solista continua l'impegno di Necta nel fornire bevande di alta qualità alle locazioni più piccole. Appartenente alla gamma table top, spicca per il design innovativo e si fa apprezzare per il suo livello di alta capacità e le sue performance tecnologiche. Le luci LED completano il design elegante e attuale, come lielegante cornice cromata che circonda l'area selezioni e i pannelli laterali colorati. Tutte queste caratteristiche dimostrano l'eccezionale livello di qualità e innovazione del design, che sicuramente diventerà il punto di riferimento per il resto del mercato.",
    },
    {
        id:9,
        Titolo: "Brio Up",
        sottotitolo: "Il gusto ineguagliabile di un piccolo grande capolavoro",
        paragrafo: "Brio Up offre una eccezionale qualità di erogazione e in più, molte importanti innovazioni. Brio Up ha un elegante design nero, dotato di un'innovativa interfaccia ed eroga bevande di qualità grazie alle affidabili tecnologie Necta. L'elettronica permette un set up della macchina estremamente facile. Brio Up è una piccola grande macchina che ha tutte le carte in regola per imporsi come la migliore della sua categori",
    }
]

export default informazioni;