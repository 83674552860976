import React from "react";
import "./chisiamo.css";
import pc from "../imginfo/pc.png"
import Sostenibilità from "../imginfo/sostenibilita.jpg"
import Prodotti from "../imginfo/prod.jpg"
import chiavi from "../imginfo/chiavi.png"

function ChiSiamo() {
    return (
        <div className="divisorio">
            <div>
                <div>
                    <p className="para1">Siamo un'azienda all'avanguardia nel settore del vending, focalizzata sulla fornitura 
                        di soluzioni di alta qualità per soddisfare le esigenze di bevande e snack dei nostri clienti. 
                    </p>
                    <p className="para2">La nostra missione è offrire un'esperienza di vending eccellente, combinando gusto, qualità, innovazione e affidabilità.</p>
                </div>
            </div>
            <div className="divSezioni">

                <div className="spazio">
                    <div className="sezioni">
                        <img src={Prodotti} alt="Prodotti"/>
                        <div className="prova">
                            <h3>Prodotti di Eccellenza</h3>
                            <p>Ampia selezione di bevande, snack e cibo sano.</p>
                            <p>Prodotti di marche rinomate e opzioni locali per soddisfare tutti i gusti.</p>
                        </div>
                    </div>
                </div>
                <div className="spazio">
                    <div className="sezioni">
                        <img src={pc} alt="tech"/>
                        <div className="prova">
                            <h3>Tecnologia all'Avanguardia</h3>
                            <p>Distributori automatici di ultima generazione con opzioni di pagamento senza contanti.</p>
                            <p>Personalizzazione delle scelte e gestione remota per un'esperienza utente unica.</p>
                        </div>
                    </div>
                </div>
                <div className="spazio">
                    <div className="sezioni">
                        <img src={chiavi} alt="chiavi in mano"/>
                        <div className="prova">
                            <h3>Pacchetto Completo "Chiavi in Mano"</h3>
                            <p>Proponiamo ai nostri clienti un pacchetto completo e "chiavi in mano".</p>
                            <p>Garantiamo un elevato standard di servizio, comprensivo della fornitura dei distributori e di una manutenzione completa.</p>
                        </div>
                    </div>
                </div>
                <div className="spazio">
                    <div className="sezioni">
                        <img src={Sostenibilità} alt="sostenibilità"/>
                        <div className="prova">
                            <h3>Sostenibilità Integrata</h3>
                            <p>Impegnati in pratiche sostenibili con imballaggi ecologici e opzioni per il riciclo.</p>
                            <p>Supportiamo produttori locali per ridurre l'impatto ambientale.</p>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    );
}

export default ChiSiamo;