const specifiche = [
    {
        id: 0,
        numero_selezionni:"fino a 36",
        altezza:"1700mm",
        larghezza:"750mm",
        profondità: "790mm",
        profondità_porta_aperta: "1470mm",
        peso:"239 Kg",
        classe_energetica:"D",
        alimentazione:"230 V, 50/60Hz",
        interfaccia:"Tablet 7'' ",
    },
    {
        id: 1,
        numero_selezionni:"fino a 56",
        altezza:"1830mm",
        larghezza:"910mm",
        profondità: "790mm",
        profondità_porta_aperta: "1660mm",
        peso:"270 Kg",
        classe_energetica:"C/D",
        alimentazione:"230 V, 50/60Hz",
        interfaccia:"Tablet 7'' ",
    },
    {
        id: 2,
        numero_selezionni:"fino a 56",
        altezza:"1830mm",
        larghezza:"910mm",
        profondità: "790mm",
        profondità_porta_aperta: "1660mm",
        peso:"270 Kg",
        classe_energetica:"C/D",
        alimentazione:"230 V, 50/60Hz",
        interfaccia:"Tablet 7'' ",
    },
    {
        id: 3,
        numero_selezionni:"fino a 24",
        altezza:"1930mm",
        larghezza:"750mm",
        profondità: "790mm",
        peso:"267 Kg",
        classe_energetica:"C/D",
        alimentazione:"230 V, 50/60Hz",
        interfaccia:"Tablet 13.3'' ",
    },
]

export default specifiche;