import React from "react";
import './cosaoffriamo.css';
import imgcoffe from '../../macchinette/imagemacchinettecoffee/concertoo.png'
import imgsnack from '../../macchinette/imagemacchinettesnack/Twist2.png'
import { useNavigate } from "react-router-dom";

function CosaOffriamo() {
    const navigate = useNavigate();

    const SnackClick = () => {
        navigate(`/cosa-offriamo/macchinette-snack`);
    };

    const CoffeeClick = () => {
        navigate(`/cosa-offriamo/macchinette-caffe`);
    };

    // const Usatoclick = () => {
    //     navigate(`/cosa-offriamo/macchinette-usato`);
    // };
    
    return(
        <div className="scelta">
            <div className="comodatouso">
                <p className="testocomodatoUso">Ecco i nostri prodotti in comodato uso</p>
            </div>
            <div className="schede">
                <div className="card">
                    <img
                    src={imgsnack}
                    alt="product-immagine"
                    className="immagineMacchinette"
                    style={{ width: '100%', height: '100%' }}
                    onClick={SnackClick}
                    />
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Snack e Bevande</h3>
                    </div>
                </div>
                <div className="card">
                    <img
                    src={imgcoffe}
                    alt="product-immagine"
                    className="immagineMacchinette"
                    style={{ width: '100%', height: '100%' }}
                    onClick={CoffeeClick}
                    />
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Caffe'</h3>
                    </div>
                </div>
            </div>
            {/* <div className="schedaUsato">
                <div className="cardusato">
                    <img
                    src={imgcoffe}
                    alt="product-immagine"
                    className="immagineMacchinette"
                    style={{ width: '100%', height: '100%' }}
                    onClick={Usatoclick}
                    />
                    <div className="content">
                        <h3 className="titoloMacchinette">Usato</h3>
                    </div>
                </div>
            </div> */}
        </div>
    );    
}

export default CosaOffriamo;
