const informazioni = [
    {
        id:0,
        titolo: "G-Drink Design 6 Vertical",
        sottotitolo: "L'evoluzione continua",
        paragrafo: "Il nostro distributore automatico più popolare, nato per erogare bevande di vari formati e dimensioni, è dotato di un'ampia vetrata frontale che garantisce la massima visibilità dei prodotti grazie anche al rinnovato sistema di illuminazione LED ancora più brillante. Nella sua versione Design Line, G-Drink è oggi munito di un'innovativa tastiera retro-illuminata e resistiva per facilitare le operazioni di selezione sia in ambienti luminosi che al buio.",
        paragrafo2: "Disponibile in due modelli, con 9 o 6 selezioni per ripiano, G-Drink Design è equipaggiato con un ascensore verticale oltre che le più avanzate tecnologie applicate al gruppo refrigerante di SandenVendo ed un facile sistema di chiusura della porta.",
    },
    {
        id:0,
        titolo: "G-Drink Design 9 Vertical",
        sottotitolo: "L'evoluzione continua",
        paragrafo: "Il nostro distributore automatico più popolare, nato per erogare bevande di vari formati e dimensioni, è dotato di un'ampia vetrata frontale che garantisce la massima visibilità dei prodotti grazie anche al rinnovato sistema di illuminazione LED ancora più brillante. Nella sua versione Design Line, G-Drink è oggi munito di un'innovativa tastiera retro-illuminata e resistiva per facilitare le operazioni di selezione sia in ambienti luminosi che al buio.",
        paragrafo2: "Disponibile in due modelli, con 9 o 6 selezioni per ripiano, G-Drink Design è equipaggiato con un ascensore verticale oltre che le più avanzate tecnologie applicate al gruppo refrigerante di SandenVendo ed un facile sistema di chiusura della porta.",
    },
]

export default informazioni;