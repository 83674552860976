import React from "react";
import {useNavigate,useLocation} from "react-router-dom";
import { FaStar } from "react-icons/fa";


function ListaBevande(props){

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        const urlParts = location.pathname.split("/");
        const lastPart = urlParts[urlParts.length - 1];

        if (lastPart === "bevande-necta") {
            navigate(`/cosa-offriamo/macchinette-snack/bevande/bevande-necta/${props.id}`);
        } else {
            navigate(`/cosa-offriamo/macchinette-snack/bevande/bevande-vendo/${props.id}`);
        }
    
    }

    return(
        <div className="listaProdotti">
        <div className="productCard" onClick={handleClick}>
          <div className="immaginiLista">
            <img
              src={props.img}
              alt="product-immagine"
              className="immagineMacchinette"
            />
          </div>
          <div className="contentCard">
            <h3 className="titoloMacchinette">{props.nome}</h3>
            <div className="stack2">
              <div className="rating">
                {[...Array(props.rating)].map((index) => (
                  <FaStar id={index + 1} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default ListaBevande;