import {React} from "react";
import imgnecta from '../../macchinette/imagemacchinettesnack/vivaceheader.png';
import imgbianchi from '../../macchinette/imagemacchinetteBianchiFas/Aria_L_EVO_master.jpg';
import imgfas from '../../macchinette/imagemacchinetteBianchiFas/900-ADVANCED.webp';
import './macchinette.css';
import { useNavigate } from "react-router-dom";

function MacchinetteSnack() {
    const navigate = useNavigate();

    const snackNecta = () => {
        navigate(`/cosa-offriamo/macchinette-snack/snack-necta`);
    };
    const snackBianchi = () => {
        navigate(`/cosa-offriamo/macchinette-snack/snack-bianchi`);
    };
    const snackFas = () => {
        navigate(`/cosa-offriamo/macchinette-snack/snack-fas`);
    };
    const bevande = () => {
        navigate(`/cosa-offriamo/macchinette-snack/bevande`);
    }

    return(
        <div className="sceltaSnackCaffe">
            <div className="schedeSnackCaffe">
                <div className="cardSnackCaffe">
                    <div className="spazioImmagine">
                        <img
                            src={imgnecta}
                            alt="product-immagine"
                            className="immagineMacchinette"
                            onClick={snackNecta}
                            />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Necta</h3>
                    </div>
                </div>
                <div className="cardSnackCaffe">
                    <div className="spazioImmagine">
                        <img
                        src={imgbianchi}
                        alt="product-immagine"
                        className="immagineMacchinette"
                        onClick={snackBianchi}
                        />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Bianchi</h3>
                    </div>
                </div>
                <div className="cardSnackCaffe">
                    <div className="spazioImmagine">
                        <img
                        src={imgfas}
                        alt="product-immagine"
                        className="immagineMacchinette"
                        onClick={snackFas}
                        />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Fas</h3>
                    </div>
                </div>
                <div className="cardSnackCaffe">
                    <div className="spazioImmagine">
                        <img
                        src={imgnecta}
                        alt="product-immagine"
                        className="immagineMacchinette"
                        style={{ width: '100%'}}
                        onClick={bevande}
                        />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Bevande</h3>
                    </div>
                </div>
            </div>

        </div>
    );    
}

export default MacchinetteSnack;
