import React, {useState} from 'react';
import './NavBar.css';
import prova from './image.png';
import { NavLink } from 'react-router-dom';
import { TfiAlignRight } from "react-icons/tfi";
import Sidebar from './sidebar';

function NavBar() { 

    const [isOpen, setIsOpen] = useState(false);

    const openSidebar = () => setIsOpen(!isOpen);
    
    return (
        <header className='lunghezzanav'>
            <nav className='wrapper' >  
                <div className='pad centro container lung'>
                    <NavLink to='/'>
                        <div className='logo'>
                            <img src={prova} alt='logo' className='immagineLogo'/>
                        </div>
                    </NavLink>
                    
                    <div className='menu centro' id='testo'>

                        <NavLink to='/'   
                        className={({ isActive}) =>
                        isActive ? "active" : "" }>
                        Home
                        </NavLink>
                        <NavLink to='/chisiamo'>Chi siamo</NavLink>
                        <NavLink to='/cosa-offriamo'>Cosa Offriamo</NavLink>
                        <NavLink to='/perche-sceglierci'>Perché Sceglierci?</NavLink>
                        <NavLink to='/contattaci'>Contattaci</NavLink>
                    </div>   
                </div>
                <div className='mobile' onClick={openSidebar}>
                            <TfiAlignRight />
                </div>
            </nav>
                <Sidebar isOpen={isOpen} closeSidebar={() => setIsOpen(false)}/>
        </header>
)
}

export default NavBar;