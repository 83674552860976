import gdrink6 from '../macchinette/imagemacchinettebevande/g_drink6.webp';
import gdrink9 from '../macchinette/imagemacchinettebevande/g_drink9.webp';

const prodotti = [
    {
        id: 0,
        img: gdrink6,
        nome: "G-Drink Design 6 Vertical",
        rating: 5
    },
    {
        id: 1,
        img: gdrink9,
        nome: "G-Drink Design 9 Vertical",
        rating: 5
    }
]

export default prodotti;