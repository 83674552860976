import fas750 from "../macchinette/imagemacchinetteBianchiFas/FAS-750-BUSINESS-23.webp";
import fas900 from "../macchinette/imagemacchinetteBianchiFas/900-ADVANCED.webp";
import fas1050 from "../macchinette/imagemacchinetteBianchiFas/1050-ADVANCED.webp";
import fasCombi from "../macchinette/imagemacchinetteBianchiFas/combi.webp";

const prodottisnack = [
    {
        id: 0,
        nome: "Fas  750",
        img: fas750,
        rating: 4
    },
    {
        id: 1,
        nome: "Fas 900",
        img: fas900,
        rating: 3
    },
    {
        id: 2,
        nome: "Fas 1050",
        img: fas1050,
        rating: 4
    },
    {
        id: 3,
        nome: "Fas Combi",
        img: fasCombi,
        rating: 4
    },
];

export default prodottisnack;