const informazioni = [
    {
        id:0,
        titolo: "ARIA L EVO MASTER",
        sottotitolo: "IL MIGLIORE RAPPORTO QUALITÀ PREZZO CHE SI ABBINA CON ELEGANZA ALLA GAMMA LEI.",
        paragrafo: "ARIA L EVO, distributore automatico a spirali refrigerato per la vendita di snack, panini, prodotti freschi, lattine e bottiglie.",
    },
    {
        id:1,
        titolo: "ARIA L EVO SLAVE",
        sottotitolo: "IL MIGLIORE RAPPORTO QUALITÀ PREZZO CHE SI ABBINA CON ELEGANZA ALLA GAMMA LEI.",
        paragrafo: "ARIA L EVO, distributore automatico a spirali refrigerato per la vendita di snack, panini, prodotti freschi, lattine e bottiglie.",
    },
    {
        id:2,
        titolo: "ARIA M SLAVE",
        sottotitolo: "IL MIGLIORE RAPPORTO QUALITÀ PREZZO CHE SI ABBINA CON ELEGANZA ALLA GAMMA LEI..",
        paragrafo: "ARIA M, distributore automatico a spirali refirigerato 3° C per la vendita di snack, panini, prodotti freschi, lattine e bottiglie.",
    },
    {
        id:3,
        titolo: "ARIA S EVO SLAVE",
        sottotitolo: "SPAZIO MINIMO PER UNA MASSIMA OFFERTA DI PRODOTTI.",
        paragrafo: "ARIA S EVO, il distributore automatico a spirali per la vendita di snack, lattine e bottiglie, abbinata a LEI300 EVO diventa una soluzione Combi Hot & Snack ideale per aree di piccole e medie dimensioni.",
    },
    {
        id:4,
        titolo: "FLESSY L MASTER",
        sottotitolo: "IL FORMATO CON CUI PUOI ALLARGARE GLI ORIZZONTI DEL TUO BUSINESS",
        paragrafo: "FLESSY L e FLESSY PLUS L ti garantiscono ampia capacità in macchina e visibilità ottimale dei prodotti in ogni contesto di consumo. Scegliendo FLESSY PLUS L hai più spazio per bottiglie e lattine, mentre con la versione slave puoi abbinare FLESSY a una macchina per bevande calde e creare un'offerta ancora più ricca e completa.",
    },
    {
        id:5,
        titolo: "FLESSY L SLAVE",
        sottotitolo: "IL FORMATO CON CUI PUOI ALLARGARE GLI ORIZZONTI DEL TUO BUSINESS.",
        paragrafo: "FLESSY L e FLESSY PLUS L ti garantiscono ampia capacità in macchina e visibilità ottimale dei prodotti in ogni contesto di consumo. Scegliendo FLESSY PLUS L hai più spazio per bottiglie e lattine, mentre con la versione slave puoi abbinare FLESSY a una macchina per bevande calde e creare un'offerta ancora più ricca e completa.",
    
    },
    {
        id:6,
        titolo: "FLESSY M MASTER",
        sottotitolo: "PERCHÉ LA FLESSIBILITÀ È ANCHE UNA QUESTIONE DI DIMENSIONI.",
        paragrafo: "FLESSY M in versione master o slave è il distributore di snack e bevande fredde pensato per chi ha bisogno di una soluzione più compatta e di meno capacità per soddisfare i suoi clienti, ma vuole comunque sfruttare le caratteristiche rivoluzionarie di FLESSY per ottenere il massimo dalla sua attività.",
    },
    {
        id:7,
        titolo: "FLESSY M SLAVE",
        sottotitolo: "PERCHÉ LA FLESSIBILITÀ È ANCHE UNA QUESTIONE DI DIMENSIONI.",
        paragrafo: "FLESSY M in versione master o slave è il distributore di snack e bevande fredde pensato per chi ha bisogno di una soluzione più compatta e di meno capacità per soddisfare i suoi clienti, ma vuole comunque sfruttare le caratteristiche rivoluzionarie di FLESSY per ottenere il massimo dalla sua attività.",
    },
    {
        id:8,
        titolo: "FLESSY XL MASTER",
        sottotitolo: "CAPACITÀ EXTRA-LARGE PER SODDISFARE LE RICHIESTE DELLE AREE PIÙ FREQUENTATE.",
        paragrafo: "Scegli FLESSY XL e FLESSY PLUS XL per avere prestazioni straordinarie in termini di capacità e offrire sempre il massimo della scelta ai tuoi clienti.",
    },
]

export default informazioni;