const informazioni = [
    {
        id:0,
        titolo: "Necta Diesis 500",
        sottotitolo: "Il futuro delle bevande fresche",
        paragrafo: "Diesis è la più innovativa soluzione attualmente disponibile per erogare bevande fresche in lattina e bottiglia. Il suo design elegante e contemporaneo è stato creato per garantire sempre un'esperienza user-friendly e un'interfaccia intuitiva. La facilità di manutenzione completa le caratteristiche di questo distributore, che con la sua linea raffinata e la sua eleganza diventerà il punto di riferimento del mercato per gli anni a venire.",
        paragrafo2: "Diesis è la più innovativa soluzione attualmente disponibile per erogare bevande fresche in lattina e bottiglia: il suo design elegante e contemporaneo e il suo interfaccia intuitivo garantiscono sempre un'esperienza user-friendly. Le dimensioni compatte non vanno a discapito della capacità distributiva.",
    },
    {
        id:1,
        titolo: "Necta Diesis 700",
        sottotitolo: "Altissima capacità produttiva, ideale per le locazioni affollate",
        paragrafo: "Diesis è la più innovativa soluzione attualmente disponibile per erogare bevande fresche in lattina e bottiglia. Il suo design elegante e contemporaneo è stato creato per garantire sempre un'esperienza user-friendly e un'interfaccia intuitiva. La facilità di manutenzione completa le caratteristiche di questo distributore, che con la sua linea raffinata e la sua eleganza diventerà il punto di riferimento del mercato per gli anni a venire.",
        paragrafo2: "Diesis è la più innovativa soluzione attualmente disponibile per erogare bevande fresche in lattina e bottiglia. Il suo design elegante e contemporaneo e il suo interfaccia intuitivo garantiscono un'esperienza sempre user-friendly. L'altissima capacità produttiva fa di questa macchina la scelta migliore per le locazioni affollate.",
    }
]

export default informazioni;