import React from "react";
import "./sidebar.css";
import { TfiClose } from "react-icons/tfi";
import { NavLink } from 'react-router-dom';

function Sidebar({ isOpen, closeSidebar }) {

  return (
    <div className={`sidebar ${isOpen ? "open" : "close"}`}>
      <div className="chiusura " onClick={closeSidebar}>
        <TfiClose size={30} />
      </div>
      <div className="side">
        <div className="menuSidebar">
          <NavLink to='/'   
            className={({ isActive }) =>
            isActive ? "active" : ""}
            onClick={closeSidebar}>
            Home
          </NavLink>
          <NavLink to='/chisiamo'
            className={({ isActive }) =>
            isActive ? "active" : ""}
            onClick={closeSidebar}
          >Chi siamo</NavLink>
          
          <NavLink to='/cosa-offriamo'
            className={({ isActive }) =>
            isActive ? "active" : ""}
            onClick={closeSidebar}
          >Cosa Offriamo</NavLink>
          <NavLink to='/perche-sceglierci'
            className={({ isActive }) =>
            isActive ? "active" : ""}
            onClick={closeSidebar}
          >Perchè Sceglierci?</NavLink>
          <NavLink to='/contattaci'
            className={({ isActive }) =>
            isActive ? "active" : ""}
            onClick={closeSidebar}
          >Contattaci</NavLink>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
