import React from "react";
import {useParams,useLocation} from "react-router-dom";
import datiVendo from "../dati/dativendobevande.js";
import datiNecta from "../dati/datinectabevande.js";
import InfoVendo from "../macchinette/datimacchinette/datibevandevendo.js"
import InfoNecta from "../macchinette/datimacchinette/datibevandenecta.js"
import testoVendo from "../dati/testoMacchinette/infobevandevendo.js";
import testoNecta from "../dati/testoMacchinette/infobevandenecta.js";

function InfoBevande() {
    const location = useLocation();
    const urlParts = location.pathname.split("/");
    const lastPart = urlParts[urlParts.length-2];
    const { id } = useParams();
    

    if (lastPart === "bevande-necta") {
        var datii = datiNecta;
        var info = InfoNecta;
        var testo = testoNecta;
    } else {
        datii = datiVendo;
        info = InfoVendo;
        testo = testoVendo;
    }

    function creaRigaDati(dato) {
        if (dato.id === parseInt(id)) {
            const campi = Object.entries(dato).filter(([chiave, valore]) =>chiave !== 'id' && valore !== '' && valore !== undefined);
            return campi.map(([chiave, valore], index) => (
                <tr key={index}>
                    <td><strong>{chiave}</strong></td>
                    <td>{valore}</td>
                </tr>
            ));
        }
    }

    return (
        <div>
            <div className="infoCoffe">
                <div className="sezioneinfoSnack">
                    <div className="informazioniSnack">
                        <h1 className="titoloSnack">{datii[id].nome}</h1>
                        <p className="sottotitolo">{testo[id].sottotitolo}</p>
                        <p className="paragrafo">{testo[id].paragrafo}</p>
                        <p className="paragrafo">{testo[id].paragrafo2}</p>
                        <p className="paragrafo">{testo[id].paragrafo3}</p>
                        <p className="paragrafo">{testo[id].paragrafo4}</p>
                    </div>
                    <div className="contenitoreImg">
                        <img src={datii[id].img} alt={datii[id].nome} className="immagine"/>
                    </div>
                </div>
                <div>
                    <h2>Specifiche del prodotto</h2>
                    <table className="tabellaDati">
                        <tbody>
                            {info.map(creaRigaDati)}
                        </tbody>
                    </table>
                </div>
    
            </div>
        </div>
    );
}

export default InfoBevande;