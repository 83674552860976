import React from "react";
import prodotti from '../../dati/daticoffe.js'
import ListaCaffe from './listacaffe.jsx'
import './macchinette.css'

function CaffeNecta() {
    return(
        <div className="listone">
            {prodotti.map(prodotti => (
                <ListaCaffe
                    id={prodotti.id}
                    img={prodotti.img}
                    nome={prodotti.nome}
                    rating={prodotti.rating}
                />
            )
            )}
        </div>
    );    
}

export default CaffeNecta;
