import React from "react";
import "./Footer.css";
import { SlSocialInstagram } from "react-icons/sl";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import Logo from "./image.png";

function Footer() {
    return(
        <footer className="footer">
            <div className="header">
                <div>
                    <img src={Logo} alt="logo" className="logoFooter" width={250}/>
                </div>
                <div className="contactFooter">
                    <div>
                        <p className="sottotitoloFooter">Contattaci</p>
                    </div>
                    <ul>
                        <li><a href="https://www.google.com/maps/search/?api=1&query=45.421522,9.429527">Strada Provinciale 181 n.11</a></li>
                        <li>26833 Merlino (Lodi)</li>
                        <li>P.IVA:03265920805</li>
                        <li>Tel. : 02/89650524</li>
                        <li>CF.: 03265920805 </li>
                        <li>Fax: 02/89830061</li>
                        <li>Mail: info@snacktimedistributori.it </li>  
                    </ul>
                </div>           
                <div className="social-icons">
                    <SlSocialInstagram className="icon"/>
                    <FaFacebookF className="icon" />
                    <FaTwitter className="icon"/>
                </div>
            </div> 
            <div className="copiright">
                @Orsini Fabio
            </div>
        </footer>
    );    
}

export default Footer;