import brio from "../macchinette/imagemacchinettecoffee/brio.png";
import briotouch from "../macchinette/imagemacchinettecoffee/BrioTouch.png";
import concerto from "../macchinette/imagemacchinettecoffee/concerto.png";
import ConcertoTouch from "../macchinette/imagemacchinettecoffee/ConcertoTouch.png";
import maestro from "../macchinette/imagemacchinettecoffee/Maestro.png";
import maestroTouch from "../macchinette/imagemacchinettecoffee/maestrotouch.png";
import Opera from "../macchinette/imagemacchinettecoffee/Opera.png";
import OperaTouch from "../macchinette/imagemacchinettecoffee/OperaTouch.png";
import solista from "../macchinette/imagemacchinettecoffee/Solista.png";
import brioUp from "../macchinette/imagemacchinettecoffee/brioUp.png";

const prodotticoffee = [
    {
        id: 0,
        nome: "Brio",
        img: brio,
        rating: 5,
    },
    {
        id: 1,
        nome: "Brio Touch",
        img: briotouch,
        rating: 5,
    },
    {
        id: 2,
        nome: "Concerto",
        img: concerto,
        rating: 5,
    },
    {
        id: 3,
        nome: "Concerto Touch",
        img: ConcertoTouch,
        rating: 5,
    },
    {
        id: 4,
        nome: "Maestro",
        img: maestro,
        rating: 5,
    },
    {
        id: 5,
        nome: "Maestro Touch",
        img: maestroTouch,
        rating: 5,
    },
    {
        id: 6,
        nome: "Opera",
        img: Opera,
        rating: 5,
    },
    {
        id: 7,
        nome: "Opera Touch",
        img: OperaTouch,
        rating: 5,
    },
    {
        id: 8,
        nome: "Solista",
        img: solista,
        rating: 5,
    },
    {
        id: 9,
        nome: "Brio Up",
        img: brioUp,
        rating: 5,
    }
];

export default prodotticoffee;