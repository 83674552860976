const specifiche = [
    {
        id: 0,
        nome: "Aria L Evo Master",
        Alimentazione_elettrica_di_rete: "220-240V, 50/60HZ",
        Potenza_assorbita: "0.3 kW ",
        Gestione_food: "predisposto per la conservazione di prodotti alimentari deperibili con una temperatura di 3 °C nei due cassetti inferiori. Dispositivo di sicurezza per il controllo della temperatura (negli ultimi due vassoi prodotti) e relativa inibizione delle spirali, qualora le condizioni a norma per prodotti deperibili dovessero venire a mancare.",
        Gruppo_frigo_facilmente_accessibile_ed_estraibile: "Manutenzione semplificata grazie al filtro antipolvere di facile estrazione.",
        Unità_refrigerante: "½ HP con sbrinamento automatico",
        Gas_refrigerante: "R 290",
        classe_energetica: "F",
        numeroVassoi:"6/7 con 8 spirali.",
        Kit: "a corredo con 8 supporti prodotti e 1 asta ferma bottiglie.",
        vetrina: "Ampia, illuminata mediante barre a led bianchi realizzata con vetro temperato che consente un'ottimale esposizione dei prodotti in vetrina. Vetrina con resistenza anti-condensa.",
        Layout:" vassoi regolabile: passo di 7,5 mm fra i fori di fissaggio delle guide vassoi per una maggiore flessibilità della configurazione interna.",
        Sportello:  "prelievo prodotti con dispositivo antifurto.",
        Sensori: "Sensore caduta prodotti.",
    },
    {
        id: 1,
        nome: "Aria L Evo Slave",
        Alimentazione_elettrica_di_rete: "220-240V, 50/60HZ",
        Potenza_assorbita: "0.3 kW ",
        Gestione_food: "predisposto per la conservazione di prodotti alimentari deperibili con una temperatura di 3 °C nei due cassetti inferiori. Dispositivo di sicurezza per il controllo della temperatura (negli ultimi due vassoi prodotti) e relativa inibizione delle spirali, qualora le condizioni a norma per prodotti deperibili dovessero venire a mancare.",
        Gruppo_frigo_facilmente_accessibile_ed_estraibile: "Manutenzione semplificata grazie al filtro antipolvere di facile estrazione.",
        Unità_refrigerante: "½ HP con sbrinamento automatico",
        Gas_refrigerante: "R 290",
        classe_energetica: "G",
        numeroVassoi:"6/7 con 8 spirali.",
        Kit: "a corredo con 8 supporti prodotti e 1 asta ferma bottiglie.",
        vetrina: "Ampia, illuminata mediante barre a led bianchi realizzata con vetro temperato che consente un'ottimale esposizione dei prodotti in vetrina. Vetrina con resistenza anti-condensa.",
        Layout:" vassoi regolabile: passo di 7,5 mm fra i fori di fissaggio delle guide vassoi per una maggiore flessibilità della configurazione interna.",
        Sportello:  "prelievo prodotti con dispositivo antifurto.",
        Sensori: "Sensore caduta prodotti.",
    },
    {
        id: 2,
        nome: "Aria M Slave",
        Alimentazione_elettrica_di_rete: "230 V - 50 Hz",
        Potenza_assorbita: "0.53 kW",
        Temperatura_interna: "fino a +8° C con temperatura ambiente = 32° C e 65% umidità. Predisposto per la conservazione di prodotti alimentari deperibili con una temperatura di 3 °C nei due cassetti inferiori. Dotate di dispositivo di sicurezza per il controllo della temperatura (negli ultimi due cassetti) e relativa inibizione delle spirali, qualora le condizioni a norma per prodotti deperibili dovessero venire a mancare.",
        Unità_Refrigerante: "⅓ HP con sbrinamento automatico.",
        Gas_refrigerante: "R 290",
        Classe_energetica: "G",
        Cassone_schiumato: "garantire un isolamento completo ed evitare dispersioni con conseguenti consumi inutili di energia.",
        vetrina :"Ampia vetrina illuminata mediante led con vetro-camera a norme antinfortunistiche, che consente un'ottimale esposizione dei prodotti in vetrina. Vetrina con resistenza anti-condensa.",
        Altezza_dei_cassetti: "regolabile.",
        Serratuta: "Serratura a codice programmabile.",
        Vano_push: "con prelievo prodotti con sistema antiintrusione.",
        Sensore: "caduta prodotti.",
    },
    {
        id: 3,
        nome: "Aria S Evo Slave",
        Alimentazione_elettrica_di_rete: "220-230 V - 50 Hz",
        Potenza_assorbita: "0.3 kW",
        Temperatura_interna: "versione combi, fino a +8° C con temperatura ambiente = 32° C e 65% umidità.",
        Unità_Refrigerante: "⅓, con sbrinamento automatico.",
        Gas_refrigerante: "R 290",
        Classe_energetica: "D",
        Cassone_schiumato: "garantire un isolamento completo ed evitare dispersioni con conseguenti consumi inutili di energia.",
        vetrina :"resistenza anti-condensa",
        Altezza_dei_cassetti: "regolabile.",
        Serratuta: "Serratura a codice programmabile.",
        Vano_push: "con prelievo prodotti con sistema antiintrusione.",
        Sensore: "caduta prodotti.",
        Altezza: "1052 mm",
        Larghezza: "680 mm",
        Peso: "155 Kg",
        Numero_Cassetti: "4 con 8 spirali."
    },
    {
        id: 4,
        nome: "Flessy L Master",
        Alimentazione_elettrica_di_rete: "220-240 V - 50-60 Hz",
        Potenza_assorbita: "0.55 kW",
        categoria_2: "tutta la macchina con temp. < 12°C", 
        categoria_3: "tutta la macchina con temp. < 3°C",
        categoria_4: "50% della macchina con temp. < 12°C e 50% con temp. < 3°C",
        dispositivo_sicurezza: " per il controllo della temperatura e relativa inibizione delle spirali, qualora le condizioni a norma per prodotti deperibili dovessero venire a mancare",
        Unità_Refrigerante: "⅓ HP con sbrinamento automatico.",
        Gas_refrigerante: "R 290",
        Classe_energetica: "D",
        Cassone_schiumato: "da 40 mm con taglio termico in pvc tra interno e esterno.",
        vetrina :" Vetrocamera da 20 mm con ampio vetro temprato basso emissivo pirolitico. ■ Sportello prelievo prodotti push ammortizzato coibentato con dispositivo antifurto.",
        Numero_Cassetti: "6 con 8 spirali.",
        selezioni: "53",
        capacità: "425",
        Altezza: "1830 mm",
        Larghezza: "830 mm",
        Profondità: "830 mm",
    },
    {
        id: 5,
        nome: "Flessy L Slave",  
        Alimentazione_elettrica_di_rete: "220-240 V - 50-60 Hz",
        Potenza_assorbita: "0.55 kW",
        categoria_2: "tutta la macchina con temp. < 12°C", 
        categoria_3: "tutta la macchina con temp. < 3°C",
        categoria_4: "50% della macchina con temp. < 12°C e 50% con temp. < 3°C",
        dispositivo_sicurezza: " per il controllo della temperatura e relativa inibizione delle spirali, qualora le condizioni a norma per prodotti deperibili dovessero venire a mancare",
        Unità_Refrigerante: "⅓ HP con sbrinamento automatico.",
        Gas_refrigerante: "R 290",
        Classe_energetica: "D",
        Cassone_schiumato: "da 40 mm con taglio termico in pvc tra interno e esterno.",
        vetrina :" Vetrocamera da 20 mm con ampio vetro temprato basso emissivo pirolitico. ■ Sportello prelievo prodotti push ammortizzato coibentato con dispositivo antifurto.",
        Numero_Cassetti: "6 con 8 spirali.",
        selezioni: "53",
        capacità: "425",
        Altezza: "1830 mm",
        Larghezza: "715 mm",
        Profondità: "830 mm",
    },
    {
        id: 6,
        nome: "Flessy M Master",
        Alimentazione_elettrica_di_rete: "220-240 V - 50-60 Hz",
        Potenza_assorbita: "0.55 kW",
        categoria_2: "tutta la macchina con temp. < 12°C", 
        categoria_3: "tutta la macchina con temp. < 3°C",
        categoria_4: "50% della macchina con temp. < 12°C e 50% con temp. < 3°C",
        dispositivo_sicurezza: " per il controllo della temperatura e relativa inibizione delle spirali, qualora le condizioni a norma per prodotti deperibili dovessero venire a mancare",
        Unità_Refrigerante: "⅓ HP con sbrinamento automatico.",
        Gas_refrigerante: "R 290",
        Classe_energetica: "D",
        Cassone_schiumato: "da 40 mm con taglio termico in pvc tra interno e esterno.",
        vetrina :" Vetrocamera da 20 mm con ampio vetro temprato basso emissivo pirolitico. ■ Sportello prelievo prodotti push ammortizzato coibentato con dispositivo antifurto.",
        Numero_Cassetti: "6 con 8 spirali.",
        selezioni: "34",
        capacità: "292",
        Altezza: "1700 mm",
        Larghezza: "715 mm",
        Profondità: "830 mm",
    },
    {
        id: 7,
        nome: "Flessy M Slave", 
        Alimentazione_elettrica_di_rete: "220-240 V - 50-60 Hz",
        Potenza_assorbita: "0.55 kW",
        categoria_2: "tutta la macchina con temp. < 12°C", 
        categoria_3: "tutta la macchina con temp. < 3°C",
        categoria_4: "50% della macchina con temp. < 12°C e 50% con temp. < 3°C",
        dispositivo_sicurezza: " per il controllo della temperatura e relativa inibizione delle spirali, qualora le condizioni a norma per prodotti deperibili dovessero venire a mancare",
        Unità_Refrigerante: "⅓ HP con sbrinamento automatico.",
        Gas_refrigerante: "R 290",
        Classe_energetica: "D",
        Cassone_schiumato: "da 40 mm con taglio termico in pvc tra interno e esterno.",
        vetrina :" Vetrocamera da 20 mm con ampio vetro temprato basso emissivo pirolitico. ■ Sportello prelievo prodotti push ammortizzato coibentato con dispositivo antifurto.",
        Numero_Cassetti: "6 con 8 spirali.",
        selezioni: "44",
        capacità: "356",
        Altezza: "1700 mm",
        Larghezza: "715 mm",
        Profondità: "830 mm",
    },
    {
        id: 8,
        nome: "Flessy XL Master",
        Alimentazione_elettrica_di_rete: "220-240 V - 50-60 Hz",
        Potenza_assorbita: "0.55 kW",
        categoria_2: "tutta la macchina con temp. < 12°C", 
        categoria_3: "tutta la macchina con temp. < 3°C",
        categoria_4: "50% della macchina con temp. < 12°C e 50% con temp. < 3°C",
        dispositivo_sicurezza: " per il controllo della temperatura e relativa inibizione delle spirali, qualora le condizioni a norma per prodotti deperibili dovessero venire a mancare",
        Unità_Refrigerante: "⅓ HP con sbrinamento automatico.",
        Gas_refrigerante: "R 290",
        Classe_energetica: "D",
        Cassone_schiumato: "da 40 mm con taglio termico in pvc tra interno e esterno.",
        vetrina :" Vetrocamera da 20 mm con ampio vetro temprato basso emissivo pirolitico. ■ Sportello prelievo prodotti push ammortizzato coibentato con dispositivo antifurto.",
        Numero_Cassetti: "6 con 10 spirali.",
        selezioni: "53",
        capacità: "425",
        Altezza: "1830 mm",
        Larghezza: "1086 mm",
        Profondità: "830 mm",
    },
]

export default specifiche;