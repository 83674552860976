import React from "react";
import {useParams,useLocation} from "react-router-dom";
import datiNectasnack from "../dati/datisnacknecta.js";
import datiBianchisnack from "../dati/datisnackBianchi.js";
import datiFassnack from "../dati/datisnackFas.js";
import "./infosnack.css";
import InfoNectasnack from "../macchinette/datimacchinette/datisnack.js";
import InfoBianchisnack from "../macchinette/datimacchinette/datisnackbianchi.js";
import InfoFassnack from "../macchinette/datimacchinette/datisnackfas.js";
import testoNecta from "../dati/testoMacchinette/infonectasnack.js";
import testoBianchi from "../dati/testoMacchinette/infobianchisnack.js";
import TestoFas from "../dati/testoMacchinette/infofasSnack.js";

function InfoSnack() {
    const location = useLocation();
    const urlParts = location.pathname.split("/");
    const lastPart = urlParts[urlParts.length-2];
    const { id } = useParams();
    

    if (lastPart === "snack-necta") {
        var datii = datiNectasnack;
        var info = InfoNectasnack;
        var testo = testoNecta
    } else if (lastPart === "snack-bianchi") {
        datii = datiBianchisnack ;
        info = InfoBianchisnack;
        testo = testoBianchi;
    } else {
        datii = datiFassnack;
        info = InfoFassnack;
        testo = TestoFas;
    }

    function creaRigaDati(dato) {
        if (dato.id === parseInt(id)) {
            const campi = Object.entries(dato).filter(([chiave, valore]) =>chiave !== 'id' && valore !== '' && valore !== undefined);
            return campi.map(([chiave, valore], index) => (
                <tr key={index}>
                    <td><strong>{chiave}</strong></td>
                    <td>{valore}</td>
                </tr>
            ));
        }
    }

    return (
        <div>
            <div className="infoSnack">
                <div className="sezioneinfoSnack">
                    <div className="informazioniSnack">
                        <h1 className="titoloSnack">{datii[id].nome}</h1>
                        <p className="sottotitolo">{testo[id].sottotitolo}</p>
                        <p className="paragrafo">{testo[id].paragrafo}</p>
                        <p className="paragrafo">{testo[id].paragrafo2}</p>
                        <p className="paragrafo">{testo[id].paragrafo3}</p>
                        <p className="paragrafo">{testo[id].paragrafo4}</p>
                    </div>
                    <div className="contenitoreImg">
                        <img src={datii[id].img} alt={datii[id].nome} className="immagine"/>
                    </div>
                </div>
                <div>
                    <h2>Specifiche del prodotto</h2>
                    <table className="tabellaDati">
                        <tbody>
                            {info.map(creaRigaDati)}
                        </tbody>
                    </table>
                </div>
    
            </div>
        </div>
    );
}

export default InfoSnack;