import React from "react";
import {useParams,useLocation} from "react-router-dom";
import datiNecta from "../dati/daticoffe.js";
import datiBianchi from "../dati/daticoffeBianchi.js";
import datiFas from "../dati/daticoffeFas.js";
import "./infocoffe.css";
import InfoNecta from "../macchinette/datimacchinette/daticoffee.js";
import InfoBianchi from "../macchinette/datimacchinette/daticoffebianchi.js";
import InfoFas from "../macchinette/datimacchinette/daticoffefas.js";
import testoNecta from "../dati/testoMacchinette/infonectacaffe.js";
import testoBianchi from "../dati/testoMacchinette/infobianchicaffe.js";
import testoFas from "../dati/testoMacchinette/infofascaffe.js";


function InfoCoffe() {
    const location = useLocation();
    const urlParts = location.pathname.split("/");
    const lastPart = urlParts[urlParts.length-2];
    const { id } = useParams();
    

    if (lastPart === "caffe-necta") {
        var datii = datiNecta;
        var info = InfoNecta;
        var testo = testoNecta;
    } else if (lastPart === "caffe-bianchi") {
        datii = datiBianchi;
        info = InfoBianchi;
        testo = testoBianchi;
    } else {
        datii = datiFas;
        info = InfoFas;
        testo = testoFas;
    }

    function creaRigaDati(dato) {
        if (dato.id === parseInt(id)) {
            const campi = Object.entries(dato).filter(([chiave, valore]) =>chiave !== 'id' && valore !== '' && valore !== undefined);
            return campi.map(([chiave, valore], index) => (
                <tr key={index}>
                    <td><strong>{chiave}</strong></td>
                    <td>{valore}</td>
                </tr>
            ));
        }
    }

    return (
        <div>
            <div className="infoCoffe">
                <div className="sezioneinfoSnack">
                    <div className="informazioniSnack">
                        <h1 className="titoloSnack">{datii[id].nome}</h1>
                        <p className="sottotitolo">{testo[id].sottotitolo}</p>
                        <p className="paragrafo">{testo[id].paragrafo}</p>
                        <p className="paragrafo">{testo[id].paragrafo2}</p>
                        <p className="paragrafo">{testo[id].paragrafo3}</p>
                        <p className="paragrafo">{testo[id].paragrafo4}</p>
                    </div>
                    <div className="contenitoreImg">
                        <img src={datii[id].img} alt={datii[id].nome} className="immagine"/>
                    </div>
                </div>
                <div>
                    <h2>Specifiche del prodotto</h2>
                    <table className="tabellaDati">
                        <tbody>
                            {info.map(creaRigaDati)}
                        </tbody>
                    </table>
                </div>
    
            </div>
        </div>
    );
}

export default InfoCoffe;