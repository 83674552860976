import React from "react";
import elencobevandeNecta from "../../dati/datinectabevande.js";
import ListaBevande from "./listabevandenecta.jsx"

function BevandeNecta() {
    return(
        <div className="listoneBevande">
            {
                elencobevandeNecta.map(elencobevandeNecta => (
                    <ListaBevande
                        id={elencobevandeNecta.id}
                        img={elencobevandeNecta.img}
                        nome={elencobevandeNecta.nome}
                        rating={elencobevandeNecta.rating}
                    />  
                ))
            }
        </div>
    );
}

export default BevandeNecta;