import React from "react";
import ListaSnack from "./listasnack";
import prodottiNecta from "../../dati/datisnacknecta.js";

function SnackNecta() {
    return(
        <div className="listone">
            {prodottiNecta.map(prodottiNecta => (
                <ListaSnack
                    id={prodottiNecta.id}
                    img={prodottiNecta.img}
                    nome={prodottiNecta.nome}
                    rating={prodottiNecta.rating}
                />
            )
            )}
        </div>
    );    
}   

export default SnackNecta;