import {React} from "react";
import imgnecta from '../../macchinette/imagemacchinettesnack/vivaceheader.png';
import './macchinette.css';
import { useNavigate } from "react-router-dom";
import './bevande.css';

function Bevande() {
    const navigate = useNavigate();

    const bevandeNecta = () => {
        navigate(`/cosa-offriamo/macchinette-snack/bevande/bevande-necta`);
    };
    const bevandeVendo = () => {
        navigate(`/cosa-offriamo/macchinette-snack/bevande/bevande-vendo`);
    };

    return(
        <div className="sceltaBevande">
            <div className="schedeBevande">
                <div className="cardBevande">
                    <div className="spazioImmagine">
                        <img
                            src={imgnecta}
                            alt="product-immagine"
                            className="immagineMacchinette"
                            onClick={bevandeNecta}
                            />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Necta</h3>
                    </div>
                </div>
                <div className="cardBevande">
                    <div className="spazioImmagine">
                        <img
                            src={imgnecta}
                            alt="product-immagine"
                            className="immagineMacchinette"
                            onClick={bevandeVendo}
                            />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Vendo</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bevande;