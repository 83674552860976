const specifiche = [
    {
        id: 0,
        nome: "Agily L",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 1,
        nome: "Brio Touch",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 2,
        nome: "Concerto",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 3,
        nome: "Concerto Touch",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 4,
        nome: "Maestro",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 5,
        nome: "Maestro Touch",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 6,
        nome: "Opera",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 7,
        nome: "Opera Touch",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 8,
        nome: "Solista",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 9,
        nome: "Brio",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 10,
        nome: "Brio Touch",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 11,
        nome: "Concerto",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 12,
        nome: "Concerto Touch",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 13,
        nome: "Maestro",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
    {
        id: 14,
        nome: "Maestro Touch",
        interfaccia: "touch 21 / smart",
        gruppoCaffe: "ES/ESV/IN",
        numcaldaie: "singola o doppia",
        tramogge: "fino a 2 standard 2ES(3,8 Kg), stardard 1ES(4,8 Kg), sottovuoto VH (2,2 Kg)",
        contenitoreProdottiSolutibiliSlimVersioneES_ESV: "fino a 10",
        contenitoreProdottiSolutibiliLargeVersioneES_ESV: "fino a 5",
        contenitoreProdottiSolutibiliSlimVersioneIN: "fino a 12",
        contenitoreProdottiSolutibiliLargeVersioneIN: "fino a 6",
        ContenitoreZuccheroSlim:"2",
        ContenitoreZuccheroLarge:"1",
        mixerES_ESV:"fino a 5",
        mixerIN: "fino a 6",
        KitTopping:"2 opzionale",
        dispenserbicchieri:" 57mm 910 pezzi, 70-74mm 650 pezzi, 80mm 440 pezzi",
        kitsecondoDispenser:"70-74mm 150 pezzi, 80mm 130 pezzi",
        capacitaBicchieri:"fino 1100 pezzi",
        capPalette:"singola/470 pezzi",
        kitsecondaPaletta: "opzionale 470 pezzi",
        altezza: "1800 mm",
        larghezza: "685 mm",
        profondita: "870 mm",
        peso: "203 Kg Smart, 212 Kg Touch",   
    },
]

export default specifiche;