import React from "react";
import ListaSnack from "./listasnack";
import prodottiFas from "../../dati/datisnackFas.js";

function SnackFas() {
    return(
        <div className="listoneFas">
            {prodottiFas.map(prodottiFas => (
                <ListaSnack
                    id={prodottiFas.id}
                    img={prodottiFas.img}
                    nome={prodottiFas.nome}
                    rating={prodottiFas.rating}
                />
            )
            )}
        </div>
    );    
}

export default SnackFas;