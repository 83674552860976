import React from "react";
import ListaSnack from "./listasnack";
import prodottiBianchi from "../../dati/datisnackBianchi.js";

function SnackBianchi() {
    return(
        <div className="listone">
            {prodottiBianchi.map(prodottiBianchi => (
                <ListaSnack
                    id={prodottiBianchi.id}
                    img={prodottiBianchi.img}
                    nome={prodottiBianchi.nome}
                    rating={prodottiBianchi.rating}
                />
            )
            )}
        </div>
    );    
}

export default SnackBianchi;