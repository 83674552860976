import {React} from "react";
import imgcoffenecta from '../../macchinette/imagemacchinettecoffee/concerto.png';
import imgcoffebianchi from '../../macchinette/imagemacchinettecaffeBianchiFas/agily_L_smart.jpg';
import imgcoffefas from '../../macchinette/imagemacchinettecoffee/fasmia.webp';
import './macchinette.css';
import { useNavigate } from "react-router-dom";

function MacchinetteCaffe() {
    const navigate = useNavigate();

    const snackNecta = () => {
        navigate(`/cosa-offriamo/macchinette-caffe/caffe-necta`);
    };
    const snackBianchi = () => {
        navigate(`/cosa-offriamo/macchinette-caffe/caffe-bianchi`);
    };
    const snackFas = () => {
        navigate(`/cosa-offriamo/macchinette-caffe/caffe-fas`);
    };

    return(
        <div className="sceltaSnackCaffe">
            <div className="schedeSnackCaffe">
                <div className="cardSnackCaffe">
                    <div className="spazioImmagine">
                        <img
                        src={imgcoffenecta}
                        alt="product-immagine"
                        className="immagineMacchinette"
                        onClick={snackNecta}
                        />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Necta</h3>
                    </div>
                </div>
                <div className="cardSnackCaffe">
                    <div className="spazioImmagine">
                        <img
                        src={imgcoffebianchi}
                        alt="product-immagine"
                        className="immagineMacchinette"
                        onClick={snackBianchi}
                        />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Bianchi</h3>
                    </div>
                </div>
                <div className="cardSnackCaffe">
                    <div className="spazioImmagine">
                        <img
                        src={imgcoffefas}
                        className="immagineMacchinette"
                        alt="product-immagine"
                        onClick={snackFas}
                        />
                    </div>
                    <div className="content">
                        <h3 className="titoloMacchinette">Macchinette Fas</h3>
                    </div>
                </div>
            </div>
        </div>
    );    
}

export default MacchinetteCaffe;
