
const specifiche = [
    {
        id: 0,
        Nome: "Diesis 500",
        Configurazione:	"5 colonne : 4D + 1S (500 lattine o 250 bottiglie) / 6 colonne : 2D + 4S (488 lattine o 244 bottliglie) / 7 colonne : 7S (476 lattine o 238 bottiglie)",
        Interfaccia_utente:	"Selezioni dirette con pulsanti meccanici",
        Capacità_prodotti: "Fino a 500 (lattine da 0.33 lt)",
        Numero_di_selezioni:"Da 5 a 7 selezioni",
        Numero_colonne:"Max 7",
        Tipo_di_colonna: "5 sel: 4D+1S / 6 sel: 2D+4S / 7 sel: 7S",
        Temperatura_di_conservazione_prodotti: "Temperaura minima: 5°C",
        Protocollo: "Executive - MDB - BDV",
        Estetiche_disponibili: "Collage / Dolce Vita / Silver",
        Potenza_massima_consumo: "740 W",
        Altezza: "1,83 m",
        Lunghezza: 	"75 cm",
        Profondità: "78,1 cm",
        Profondità_con_porta_aperta:"1,51 m",
        Peso:"280 kg",
        Tensione_di_alimentazione:"230 V",
        Frequenza:"50 Hz",
    },
    {
        id: 1,
        Nome: "Diesis 700",
        Configurazione:	"7 colonne : 5D + 2S (676 lattine o 338 bottiglie) / 8 colonne : 3D + 5S (664 lattine or 332 bottiglie) / 9 colonne : 1D + 8S (652 lattine o 326 bottiglie)",
        Interfaccia_utente:	"Selezioni dirette con pulsanti meccanici",
        Capacità_prodotti: "Fino a 676 (lattine da 0.33 lt)",
        Numero_di_selezioni:"Da 7 a 9 selezioni",
        Numero_colonne:"Max 9",
        Tipo_di_colonna: "6 sel: 6D / 7 sel: 5D+2S / 8 sel: 3D+5S / 9 sel: 1D+8S",
        Temperatura_di_conservazione_prodotti: "Temperaura minima: 5°C",
        Protocollo: "Executive - MDB - BDV",
        Estetiche_disponibili: "Collage / Dolce Vita / Silver",
        Potenza_massima_consumo: "740 W",
        Altezza: "1,83 m",
        Lunghezza: 	"98,5 cm",
        Profondità: "78,1 cm",
        Profondità_con_porta_aperta:"1,75 m",
        Peso:"330 kg",
        Tensione_di_alimentazione:"230 V",
        Frequenza:"50 Hz",
    }
]

export default specifiche;