const specifiche = [
    {
        id: 0,
        altezza:"1700mm",
        larghezza:"530mm",
        profondità: "580mm",
        profondità_porta_aperta: "980mm",
        peso:"110Kg",
        contenitori_prodotti_solubili: "6",
        alimentazione:"230 V, 50/60Hz",
        interfaccia:"Tablet 13,3'' ",
    },
    {
        id: 1,
        altezza:"1830mm",
        larghezza:"650mm",
        profondità: "690mm",
        profondità_porta_aperta: "1310mm",
        peso:"151Kg",
        contenitori_prodotti_solubili: "8",
        alimentazione:"230 V, 50/60Hz",
        interfaccia:"Tablet 13,3'' ",
    },
    {
        id: 2,
        altezza:"1700mm",
        larghezza:"660mm",
        profondità: "730mm",
        profondità_porta_aperta: "1320mm",
        peso:"151Kg",
        contenitori_prodotti_solubili: "7",
        alimentazione:"230 V, 50/60Hz",
        interfaccia:"Tablet 21.5'' ",
    },
]

export default specifiche;