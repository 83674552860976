import diesis500 from '../macchinette/imagemacchinettebevande/necta_diesis_500.jpg'
import diesis700 from '../macchinette/imagemacchinettebevande/necta_diesis_700.jpg'

const prodotti = [
    {
        id: 0,
        img: diesis500,
        nome: "Diesis 500",
        rating: 3,
    },
    {
        id: 1,
        img: diesis700,
        nome: "Diesis 700",
        rating: 4,
    },
]

export default prodotti;