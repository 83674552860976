const specifiche = [
    {
        id: 0,
        nome: "G Drink Design 6 Vertical",
        dimensioni_AxLxP:"1830mm (con piedini regolabili) x 940mm x 845mm",
        peso_netto_della_macchina_standard_senza_optional: "295 Kg",
        numero_di_sezioni_per_ripiano: "6",
        numero_di_ripiani:"Standard 5 / Max. 7",
        numero_di_selezioni:"Standard 30 / Max. 42",
        capacità_massima_lattine:"336  (33cl)",
        capacità_massima_bottiglie:"240 0,5L PET",
        protocollo: "Executive / MDB",
        controllo_sistema_refrigerante: "Elettronico",
        sistema_refrigerante:"HC2",
        temperatura_interna:	"+2°C Fino a +10°C",
        massimo_consumo_energetico: "450 W",
        alimentazione: "230/240 V - 50/60 Hz",
        classe_energetica: "D",
        consumo_energetico: "1330 (kWh/annum)",
        optional:"",
        serratura_codificata_rielda:"",
        protezione_serratura:"",
        inserimento_monete_antivandalismo:"",
        sicurezza_su_scatola_di_motene:"",
        kit_tettuccio_protezione:"",
        kit_fissaggi_a_muro_terrasistema_di_pagamento:"",
        kit_eva_dts:"",
        vari_kit_di_montaggio:"",
        personalizzazione:"",
        colore_mantello_su_richiesta:""
    },
    {
        id: 1,
        nome: "G Drink Design 9 Vertical",
        dimensioni_AxLxP:"1830mm (con piedini regolabili) x 1215mm x 845mm",
        peso_netto_della_macchina_standard_senza_optional: "345 Kg",
        numero_di_sezioni_per_ripiano: "9",
        numero_di_ripiani:"Standard 5 / Max. 7",
        numero_di_selezioni:"Standard 45 / Max. 63",
        capacità_massima_lattine:"504  (33cl)",
        capacità_massima_bottiglie:"360 0,5L PET",
        protocollo: "Executive / MDB",
        controllo_sistema_refrigerante: "Elettronico",
        sistema_refrigerante:"HC2",
        temperatura_interna:	"+2°C Fino a +10°C",
        massimo_consumo_energetico: "450 W",
        alimentazione: "230/240 V - 50/60 Hz",
        classe_energetica: "D",
        consumo_energetico: "1562 (kWh/annum)",
        optional:"",
        serratura_codificata_rielda:"",
        protezione_serratura:"",
        inserimento_monete_antivandalismo:"",
        sicurezza_su_scatola_di_motene:"",
        kit_tettuccio_protezione:"",
        kit_fissaggi_a_muro_terrasistema_di_pagamento:"",
        kit_eva_dts:"",
        vari_kit_di_montaggio:"",
        personalizzazione:"",
        colore_mantello_su_richiesta:""
    }
]

export default specifiche;