import AgilyLS from "../macchinette/imagemacchinettecaffeBianchiFas/agily_L_smart.jpg";
import AgilyLT from "../macchinette/imagemacchinettecaffeBianchiFas/agily_L-2.jpg";
import AgilyMS from "../macchinette/imagemacchinettecaffeBianchiFas/agily_M_smart-1.jpg";
import AgilyMT from "../macchinette/imagemacchinettecaffeBianchiFas/agily_M-1.jpg";
import intuity21 from "../macchinette/imagemacchinettecaffeBianchiFas/intuity_21.jpg";
import intuity32 from "../macchinette/imagemacchinettecaffeBianchiFas/intuity_32-1.jpg";
import Lei2go from "../macchinette/imagemacchinettecaffeBianchiFas/Lei2go.jpg";
import Lei250 from "../macchinette/imagemacchinettecaffeBianchiFas/Lei250_DYB.jpg";
import Lei300 from "../macchinette/imagemacchinettecaffeBianchiFas/Lei300-touch.jpg";
import Lei400 from "../macchinette/imagemacchinettecaffeBianchiFas/Lei400_standard.jpg";
import Lei400t from "../macchinette/imagemacchinettecaffeBianchiFas/Lei400_touch.jpg";
import Lei600 from "../macchinette/imagemacchinettecaffeBianchiFas/Lei600_plus.jpg";
import Lei600s from "../macchinette/imagemacchinettecaffeBianchiFas/Lei600_standard.jpg";
import Lei600t from "../macchinette/imagemacchinettecaffeBianchiFas/Lei600_touch.jpg";
import Lei700 from "../macchinette/imagemacchinettecaffeBianchiFas/Lei700_plus.jpg";


const prodotticoffee = [
    {
        id: 0,
        nome: "Agily L Smart",
        img: AgilyLS,
        rating: 5
    },
    {
        id: 1,
        nome: "Agily L Touch 21''",
        img: AgilyLT,
        rating: 5
    },
    {
        id: 2,
        nome: "Agily M Smart",
        img: AgilyMS,
        rating: 5
    },
    {
        id: 3,
        nome: "Agily M Touch 21",
        img: AgilyMT,
        rating: 5
    },
    {
        id: 4,
        nome: "Intuity Plus Touch 32''",
        img: intuity21,
        rating: 5
    },
    {
        id: 5,
        nome: "Intuity Touch 21''",
        img: intuity32,
        rating: 5
    },
    {
        id: 6,
        nome: "LEI250 Easy/Smart/Touch 15''",
        img: Lei2go,
        rating: 5
    },
    {
        id: 7,
        nome: "LEI2GO Touch 32''",
        img: Lei250,
        rating: 5
    },
    {
        id: 8,
        nome: "LEI301 EVO Easy/Smart/Touch 15''",
        img: Lei300,
        rating: 5
    },
    {
        id: 9,
        nome: "LEI400",
        img: Lei400,
        rating: 5
    },
    {
        id: 10,
        nome: "LEI400 Easy/Smart/Touch 21''",
        img: Lei400t,
        rating: 5
    },
    {
        id: 11,
        nome: "LEI600",
        img: Lei600,
        rating: 5
    },
    {
        id: 12,
        nome: "LEI600 Easy/Smart/Touch 21''",
        img: Lei600s,
        rating: 5
    },
    {
        id: 13,
        nome: "LEI600 Touch 21'' + Modulo Coffe To Go",
        img: Lei600t,
        rating: 5
    },
    {
        id: 14,
        nome: "LEI700 Touch 32'' + Modulo Coffe To Go",
        img: Lei700,
        rating: 5
    },
];

export default prodotticoffee;