import React from "react";
import './macchinette.css'
import { NavLink } from "react-router-dom";

function MacchinetteUsato() {
    return(
        <div className="spazioUsato">
            <div>
                <p className="titoloUsato">Volete sapere quali macchinette usate abbiamo?</p>
                <p className="sottotitoloUsato">Contattateci qui!</p>
                <div>
                    <NavLink to="/contattaci">
                        <button className="bottoneusato">Contattaci</button>
                    </NavLink>
                </div>
            </div>
        </div>
    );    
}

export default MacchinetteUsato;
