import React from "react";
// import geolocal from "../imginfo/geolocal.png";
import servizio from "../imginfo/servizi.png";
import innovazione from "../imginfo/lampadina.png";
import qualità from "./imagehomepage/altaqualita.png";


import "./chisiamo.css";

function PercheSceglierci() {
    return(
        <div className="divisorio">
            {/* <div className="spazio">
                <div className="sezioni" style={{ boxShadow: "0 0 10px rgba(0,0,240,2)" }}>
                    <img src={geolocal} alt="localizzazione"/>
                    <div className="prova">
                        <h3>Localizzazione Strategica</h3>
                        <p>Posizioniamo i nostri distributori in luoghi ad alta visibilità e con un elevato 
                            flusso di persone, garantendo una maggiore redditività per i nostri clienti.</p>
                    </div>
                </div>
            </div> */}
            <div className="divSezioni">                
                <div className="spazio">
                    <div className="sezioni" style={{ boxShadow: "0 0 10px rgba(0,0,240,2)" }}>
                        <img src={servizio} alt="servizio clienti"/>
                        <div className="prova">
                            <h3>Servizio Clienti Eccellente</h3>
                            <p>Il nostro team è dedicato a garantire un'assistenza tempestiva e risolvere qualsiasi 
                                problema per garantire la massima soddisfazione del cliente.</p>
                        </div>
                    </div>
                </div>  
            </div>
            <div className="divSezioni">  
                <div className="spazio">
                    <div className="sezioni" style={{ boxShadow: "0 0 10px rgba(0,0,240,2)" }}>
                        <img src={innovazione} alt="innovazione"/>
                        <div className="prova">
                            <h3>Innovazione Continua</h3>
                            <p>Investiamo costantemente in nuove tecnologie e pratiche aziendali per 
                                rimanere al passo con le esigenze in evoluzione del mercato.</p>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="divSezioni">  
                <div className="spazio">
                    <div className="sezioni" style={{ boxShadow: "0 0 10px rgba(0,0,240,2)" }}>
                        <img src={qualità} alt="innovazione"/>
                        <div className="prova">
                            <h3>Prodotti di prima qualità</h3>
                            <p>La nostra azienda offre prodotti di prima qualità che si distinguono per la loro eccellenza e soddisfazione garantita. Grazie alla selezione 
                                accurata delle materie prime e ai rigorosi controlli di qualità, garantiamo freschezza, autenticità e gusto senza paragoni ai nostri prodotti</p>
                        </div>
                    </div>
                </div>    
            </div>      
        </div>
    );    
}

export default PercheSceglierci;