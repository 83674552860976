import festival from "../macchinette/imagemacchinettesnack/festival.png";
import minihead from "../macchinette/imagemacchinettesnack/minihead.png";
import OrchestraHero from "../macchinette/imagemacchinettesnack/OrchestraHero.png";
import OrchestraTouchHero from "../macchinette/imagemacchinettesnack/OrchestraTouchHero.png";
import SopranoHero from "../macchinette/imagemacchinettesnack/SopranoHero.png";
import SwingHeader from "../macchinette/imagemacchinettesnack/SwingHeader.png";
import SwingIsSide from "../macchinette/imagemacchinettesnack/Swings_is_side.png";
import Twistheader from "../macchinette/imagemacchinettesnack/Twistheader.png";
import VivaceHeader from "../macchinette/imagemacchinettesnack/vivaceheader.png";
import Compattatore from "../macchinette/imagemacchinettesnack/Compattatore.png";
import Combi from "../macchinette/imagemacchinettesnack/combi.jpg";



const prodottisnack = [
    {
        id: 0,
        nome: "Festival",
        img: festival,
        rating: 4,
    },
    {
        id: 1,
        nome: "Minisnakky",
        img: minihead,
        rating: 4,
    },
    {
        id: 2,
        nome: "Orchestra",
        img: OrchestraHero,
        rating: 3,
    },
    {
        id: 3,
        nome: "Orchestra Touch",
        img: OrchestraTouchHero,
        rating: 4,
    },
    {
        id: 4,
        nome: "Soprano",
        img: SopranoHero,
        rating: 4,
    },
    {
        id: 5,
        nome: "Swing",
        img: SwingHeader,
        rating: 3,
    },
    {
        id: 6,
        nome: "Swing SL",
        img: SwingIsSide,
        rating: 4,
    },
    {
        id: 7,
        nome: "Twist",
        img: Twistheader,
        rating: 4,
    },
    {
        id: 8,
        nome: "Vivace",
        img: VivaceHeader,
        rating: 3,
    },
    {
        id: 9,
        nome: "Greeny Touch",
        img: Compattatore,
        rating: 3,
    },
    {
        id: 10,
        nome: "Combo Brio 3 + Minisnakky",
        img: Combi,
        rating: 3,
    }
];

export default prodottisnack;