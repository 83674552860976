const informazioni = [
    {
        id: 0,
        sottotitolo: "La festa della freschezza",
        paragrafo:"Festival è la soluzione ideale per le locazioni che necessitano di un servizio continuo 24 su 24, 7 giorni su 7. Non un semplice distributore, ma una vera e propria mensa automatica. L'estrema flessibilità di configurazione permette all'utente di scegliere tra snack, drink, piatti freschi, frutta e yogurt."
    },
    {
        id: 1,
        sottotitolo: "Grande efficienza in dimensioni compatte",
        paragrafo:"Creato per soddisfare le esigenze di snack per le piccole locazioni, MiniSnakky R600a completa la gamma Necta offrendo - con la sua capacità di erogare bottiglie, lattine e snack - una soluzione perfetta per questi ambienti.",
        paragrafo2:"Grazie alle dimensioni compatte e alle funzioni user-friendly, questo modello slave può essere posto in batteria con distributori come Brio Touch, Brio, Brio Up o Solista, per offrire una proposta completa di snack e bevande in quelle locazioni dove lo spazio è limitato ma non si vuole rinunciare alla qualità e alla varietà."
    },
    {
        id: 2,
        sottotitolo: "Flessibile, elegante e innovativo: signore e signori, ecco Orchestra!",
        paragrafo:"Orchestra è la nuova famiglia di distributori Impulse a 8 spirali dalle prestazioni incredibili,  progettata per le locazioni più esigenti.",
        paragrafo2: "Tra le tante innovazioni, i vassoi VisioShop trasparenti, modulari e riconfigurabili e gli elevati livelli di efficienza energetica.",
        paragrafo3: "Inoltre Orchestra ha un design elegante: il cabinet in alluminio anodizzato nero presenta un'area di interfaccia in metallo nero con cornice diamantata, che ospita un ampio display grafico a colori insieme a una tastiera numerica capacitiva.",
        paragrafo4: "Innovazioni tecnologiche, estetica raffinata, prestazioni eccellenti: Orchestra ha tutto il necessario per affermarsi come nuovo punto di riferimento tra i distributori automatici snack&food per le locazioni più esigenti.",
    },
    {
        id: 3,
        sottotitolo: "Orchestra Touch, l'innovazione più flessibile che ci sia.",
        paragrafo:"Orchestra Touch completa la nuova famiglia di distributori a 8 spirali con una versione dall'interfaccia touch.",
        paragrafo2: "Oltre alle note innovazioni, come i vassoi VisioShop trasparenti, modulari e riconfigurabili, e agli elevati livelli di efficienza energetica, questo modello dispone di un'area di selezione touch semplice ed intuitiva. Tramite lo schermo, la selezione dei prodotti e l'accesso alle informazioni sono facili e immediati. L'utente potrà navigare tra le offerte e comprare più prodotti con una sola transazione mentre l'operatore potrà costruire un'offerta su misura per ogni locazione.",
        paragrafo3: "Orchestra Touch mantiene il design elegante della famiglia caratterizzato da un cabinet in alluminio anodizzato nero con un'area di interfaccia in metallo nero con cornice diamantata. Innovazioni tecnologiche, estetica raffinata, touch screen innovativo: Orchestra Touch è la soluzione più performante per le locazioni più esigenti.",
    },
    {
        id: 4,
        sottotitolo: "Possibilità illimitate per tutti i gusti.",
        paragrafo:"Soprano è il nuovissimo modello che completa il rinnovamento della gamma Necta Impulse.",
        paragrafo2: "Con il suo design lucido, essenziale e frameless, Soprano è il distributore più adatto per attirare anche gli utenti più esigenti che potranno godere di un'offerta di prodotti e di una capacità senza pari. I vassoi, ognuno dei quali può ospitare fino a 10 selezioni singole, sono distribuiti in modo da ottimizzare lo spazio all'interno della cella; il layout estremamente flessibile, abbinato a una eccellente classe di efficienza energetica, rappresenta la soluzione ideale per le posizioni semi-pubbliche.",
    },
    {
        id: 5,
        sottotitolo: "La novità più fresca sul mercato",
        paragrafo:"Swing è il nuovo distributore Necta che offre alte performance ed eccellente qualità al segmento core del mercato Impulse.",
        paragrafo2: "Questo modello snack&food risponde all'esigenza di avere una macchina affidabile e ad alta capacità, flessibile e compatta che possa essere personalizzata per quanto riguarda la varietà dei prodotti in offerta.",
        paragrafo3: "Grazie a una configurazione interna ottimizzata e flessibile, Swing permette di gestire in modo profittevole innumerevoli tipologie di packaging e di prodotti.",
        paragrafo4: "Inoltre, Swing ha una efficienza energetica tra le migliori del mercato. Ha raggiunto la classe C secondo la nuova scala europea di valutazione dei consumi.",
    },
    {
        id: 6,
        sottotitolo: "Swing SL, la novità più fresca sul mercato",
        paragrafo:"Swing SL è il nuovo distributore slave di Necta che offre alte performance ed eccellente qualità al segmento core del mercato Impulse.",
        paragrafo2: "Questo modello SL risponde all'esigenza di avere una macchina affidabile, ad alta capacità, compatta ma flessibile; che possa essere personalizzata per quanto riguarda la varietà dei prodotti in offerta.",
        paragrafo3:"Swings SL beneficia di una configurazione interna ottimizzata e flessibile che consente la gestione di innumerevoli tipologie di packaging e di prodotti.",
        paragrafo4:"Inoltre, Swing SL ha un'efficienza energetica tra le migliori del mercato. Ha raggiunto la classe C, secondo la nuova scala europea di valutazione dei consumi.",
    },
    {
        id: 7,
        sottotitolo: "La più verde delle stelle del Vending",
        paragrafo:"In termini di efficienza energetica e prestazioni, Twist è una delle novità più interessanti per il segmento dei distributori a sei spirali.",
        paragrafo2: "Il nuovo distributore automatico Twist rappresenta un'importante evoluzione, proponendo soluzioni tecnologiche tipiche dei prodotti di fascia alta della nostra gamma.",
        paragrafo3: "Efficienza energetica, attenzione all'ambiente, qualità e prestazioni avanzate: sono questi i punti di forza di questo nuovo modello Snack&Food. Vediamolo nello specifico.",
    },
    {
        id: 8,
        sottotitolo: "La più verde delle stelle nella Gamma Impulse",
        paragrafo:"Insieme a Twist, Vivace è una delle novità più interessanti per il segmento dei distributori a sei spirali in termini di efficienza energetica e prestazioni.",
        paragrafo2: "Il nuovo modello Vivace rappresenta un'importante evoluzione dedicata alle piccole e medie locazioni, proponendo soluzioni tecnologiche tipiche dei prodotti di fascia alta della nostra gamma.",
        paragrafo3: "Efficienza energetica, design raffinato e prestazioni avanzate: sono questi i punti di forza di questo nuovo modello snack&food. Vediamolo nello specifico.",
    },
    {
        id: 9,
        sottotitolo: "La nostra soluzione per una pausa sostenibile",
        paragrafo:"Greeny touch è il modulo compattatore che promuove le azioni a tutela dell'ambiente attraverso la riduzione della plastica e il suo reciclo, con conseguente risparmio sui costi legati allo smaltimento e al trasporto.",
        paragrafo2:"L'installazione di questo modulo, consigliata in abbinamento a un modello Impulse, facilita, incoraggia e semplifica la raccolta differenziata della plastica, stimolando allo stesso tempo una maggiore consapevolezza ambientale.",
        paragrafo3:"Greeny Touch offe un incentivo per ogni vuoto correttamente smaltito: grazie al sistema cashless Hi! installato sui distributori, l'utente finale riceve un cashback sulla propria app di pagamento per ogni scarto plastico inserito nel compattatore",
    },
    {
        id: 10,
        sottotitolo: "La nostra soluzione per una pausa sostenibile",
        paragrafo:"Brio 3 WayCap è una macchina affidabile dedicata alla preparazione di bevande di qualità, perfetto per le piccole e medie locazioni. Brio 3 WayCap: tutta la qualità di Necta, tutto l'aroma del vostro espresso preferito.",
        paragrafo2: "MiniSnakky H.E. completa la gamma Necta offrendo, con la sua capacità di erogare bottiglie, lattine e snack, una soluzione perfetta per questi ambienti. Grazie alle dimensioni compatte e alle funzioni user-friendly, questo modello slave può essere posto in batteria con distributori come Brio Up o Solista, per offrire una proposta completa di snack e bevande in quelle locazioni dove lo spazio è limitato ma non si vuole rinunciare alla qualità. ",
    }
]

export default informazioni;