import React from "react";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import './lista.css';
import { useLocation } from "react-router-dom";

function ListaSnack(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    const urlParts = location.pathname.split("/");
    const lastPart = urlParts[urlParts.length - 1];

    if (lastPart === "snack-necta") {
      navigate(`/cosa-offriamo/macchinette-snack/snack-necta/${props.id}`);
    } else if(lastPart === "snack-bianchi") {
      navigate(`/cosa-offriamo/macchinette-snack/snack-bianchi/${props.id}`);
    }
    else{
      navigate(`/cosa-offriamo/macchinette-snack/snack-fas/${props.id}`);
    }

  };

  return (
      <div className="listaProdotti">
        <div className="productCard" onClick={handleClick}>
          <div className="immaginiLista">
            <img
              src={props.img}
              alt="product-immagine"
              className="immagineMacchinette"
            />
          </div>
          <div className="contentCard">
            <h3 className="titoloMacchinette">{props.nome}</h3>
            <div className="stack2">
              <div className="rating">
                {[...Array(props.rating)].map((index) => (
                  <FaStar id={index + 1} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ListaSnack;
