import NavBar from './components/NavBar';
import {Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Chisiamo from './components/chisiamo';
import CosaOffriamo from './components/cosaoffriamo/cosaoffriamo';
import PercheSceglierci from './components/sceglierci';
import Contattaci from './components/contattaci';
import Layout1 from './macchinette/infocoffee';
import Layout from './macchinette/infosnack';
//import Layout2 from './macchinette/infousato';
import Footer from './components/Footer';
import Snack from './components/cosaoffriamo/macchinettesnack';
import Caffe from './components/cosaoffriamo/macchinettecaffe';
import Usato from './components/cosaoffriamo/macchinetteusato';
import SnackNecta from './components/cosaoffriamo/snackNecta';
import SnackBianchi from './components/cosaoffriamo/snackBianchi';
import SnackFas from './components/cosaoffriamo/snackFas';
import CaffeNecta from './components/cosaoffriamo/caffeNecta';
import CaffeBianchi from './components/cosaoffriamo/caffeBianchi';
import CaffeFas from './components/cosaoffriamo/caffeFas';
import Bevande from './components/cosaoffriamo/bevande';
import BevandeNecta from './components/cosaoffriamo/bevandeNecta';
import BevandeVendo from './components/cosaoffriamo/bevandeVendo';
import LayoutBevande from './macchinette/infobevande';

function App(){
  return (
    <div className="App">
      <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chisiamo" element={<Chisiamo />} />
          <Route path="/cosa-offriamo" element={<CosaOffriamo />} />
          <Route path="/perche-sceglierci" element={<PercheSceglierci/>}/>
          <Route path="/contattaci" element={<Contattaci />} />
          <Route path="/cosa-offriamo/macchinette-snack" element={<Snack />} />
          <Route path="/cosa-offriamo/macchinette-caffe" element={<Caffe />} />
          <Route path="/cosa-offriamo/macchinette-usato" element={<Usato />} />
          <Route path="/cosa-offriamo/macchinette-snack/snack-necta" element={<SnackNecta/>} />
          <Route path="/cosa-offriamo/macchinette-snack/snack-bianchi" element={<SnackBianchi />} />
          <Route path="/cosa-offriamo/macchinette-snack/snack-fas" element={<SnackFas />} />
          <Route path="/cosa-offriamo/macchinette-snack/bevande" element={<Bevande />} />
          <Route path="/cosa-offriamo/macchinette-snack/bevande/bevande-necta" element={<BevandeNecta />} />
          <Route path="/cosa-offriamo/macchinette-snack/bevande/bevande-vendo" element={<BevandeVendo />} />
          <Route path="/cosa-offriamo/macchinette-snack/bevande/bevande-necta/:id" element={<LayoutBevande />} />
          <Route path="/cosa-offriamo/macchinette-snack/bevande/bevande-vendo/:id" element={<LayoutBevande />} />
          <Route path="/cosa-offriamo/macchinette-snack/snack-necta/:id" element={<Layout />} />
          <Route path="/cosa-offriamo/macchinette-snack/snack-bianchi/:id" element={<Layout />} />
          <Route path="/cosa-offriamo/macchinette-snack/snack-fas/:id" element={<Layout />} />
          
          <Route path="/cosa-offriamo/macchinette-caffe/caffe-necta" element={<CaffeNecta/>} />
          <Route path="/cosa-offriamo/macchinette-caffe/caffe-bianchi" element={<CaffeBianchi />} />
          <Route path="/cosa-offriamo/macchinette-caffe/caffe-fas" element={<CaffeFas />} />
          <Route path="/cosa-offriamo/macchinette-caffe/caffe-necta/:id" element={<Layout1 />} />
          <Route path="/cosa-offriamo/macchinette-caffe/caffe-bianchi/:id" element={<Layout1 />} />
          <Route path="/cosa-offriamo/macchinette-caffe/caffe-fas/:id" element={<Layout1 />} />
        </Routes>
      <Footer />

    </div>
  );
}

export default App;
