import React from "react";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import './lista.css';

function ListaCaffe(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    const urlParts = location.pathname.split("/");
    const lastPart = urlParts[urlParts.length - 1];

    if (lastPart === "caffe-necta") {
      navigate(`/cosa-offriamo/macchinette-caffe/caffe-necta/${props.id}`);
    } else if(lastPart === "caffe-bianchi") {
      navigate(`/cosa-offriamo/macchinette-caffe/caffe-bianchi/${props.id}`);
    }
    else{
      navigate(`/cosa-offriamo/macchinette-caffe/caffe-fas/${props.id}`);
    }

  };

  return (
    <div>
      <div className="productCard" onClick={handleClick}>
        <div className="immaginiLista">
          <img
            src={props.img}
            alt="product-immagine"
            className="immagineMacchinette"
          />
        </div>
        <div className="contentCard">
          <h3 className="titoloMacchinette">{props.nome}</h3>
          <div className="stack2">
            <div className="rating">
              {[...Array(props.rating)].map((index) => (
                <FaStar id={index + 1} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListaCaffe;