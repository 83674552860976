const specifiche = [
    {
        id: 0,
        nome: "Brio",
        vesione: "Espresso",
        capacita: "300",
        interfaccia: "Tastiera",
        numselezioni:"",
        numcontenitori: "7",
        nummixer: "3",
        capPalette: "230",
        gruppoCaffe: "Z4000",
        numcaldaie: "1",
        protoccolo: "Executive - MDB",
        altezza: "760 mm",
        larghezza: "600 mm",
        profondita: "660 mm",
        profonditaPortaAperta: "1160 mm",
        peso: "70 Kg",
        voltaggio: "230 V",
        consumo: "1750 W",
        frequenza: "50 Hz",
    },
    {
        id: 1,
        nome: "Brio Touch",
        vesione: "Espresso",
        capacita: "300",
        interfaccia: "Touch Screen",
        numselezioni:"",
        numcontenitori: "7",
        nummixer: "3",
        capPalette: "230",
        gruppoCaffe: "Z4000",
        numcaldaie: "1",
        protoccolo: "Executive - MDB",
        altezza: "7600 mm",
        larghezza: "600 mm",
        profondita: "660 mm",
        profonditaPortaAperta: "1160 mm",
        peso: "70 Kg",
        voltaggio: "230 V",
        consumo: "1750 W",
        frequenza: "50 Hz",
    },
    {
        id: 2,
        nome: "Concerto",
        vesione: "Doppio Espresso",
        capacita: "500",
        interfaccia: "Selezioni Dirette",
        numselezioni: "fino a 16",    
        numcontenitori: "da 5 a 6",
        nummixer: "3",
        capPalette: "450",
        gruppoCaffe: "Espresso Z4000",
        numcaldaie: "1",
        protoccolo: "Executive o MDB o BDV",
        altezza: "1700 mm",
        larghezza: "600 mm",
        profondita: "740 mm",
        profonditaPortaAperta: "1250 mm",
        peso: "circa 125 Kg",
        voltaggio: "230 V",
        consumo: "1850 W",
        frequenza: "50 Hz",
    },
    {
        id: 3,
        nome: "Concerto Touch",
        vesione: "Doppio Espresso",
        capacita: "500",
        interfaccia: "Touch Screen, 13,3 FULL HD",
        numselezioni:"60",
        numcontenitori: "8",
        nummixer: "",
        capPalette: "",
        gruppoCaffe: "Espresso Z4000",
        numcaldaie: "1",
        protoccolo: "Executive - MDB - BDV",
        altezza: "1700 mm",
        larghezza: "600 mm",
        profondita: "740 mm",
        profonditaPortaAperta: "1250 mm",
        peso: "135 Kg",
        voltaggio: "230 V",
        consumo: "1850 W",
        frequenza: "50 Hz",
    },
    {
        id: 4,
        nome: "Maestro",
        vesione: "Doppio Espresso",
        capacita: "650 + 440",
        interfaccia: "Selezione Diretta",
        numselezioni:"fino a 22",
        numcontenitori: "9",
        nummixer: "4",
        capPalette: "950",
        gruppoCaffe: "Espresso Z4000",
        numcaldaie: "2",
        protoccolo: "Executive - MDB",
        altezza: "1830 mm",
        larghezza: "650 mm",
        profondita: "760 mm",
        profonditaPortaAperta: "1320 mm",
        peso: "170 Kg",
        voltaggio: "230 V",
        consumo: "2500 W",
        frequenza: "50 Hz",
    },
    {
        id: 5,
        nome: "Maestro Touch",
        vesione: "Doppio Espresso Dual Cup",
        capacita: "650 + 440",
        interfaccia: "Touch Screen",
        numselezioni:"piu' di 100",
        numcontenitori: "9",
        nummixer: "4",
        capPalette: "950",
        gruppoCaffe: "Z4000",
        numcaldaie: "2",
        protoccolo: "Executive - MDB",
        altezza: "1830 mm",
        larghezza: "650 mm",
        profondita: "760 mm",
        profonditaPortaAperta: "1620 mm",
        peso: "170 Kg",
        voltaggio: "230 V",
        consumo: "2500 W",
        frequenza: "50 Hz",
    },
    {
        id: 6,
        nome: "Opera",
        vesione: "Doppio Espresso Dual Cup",
        capacita: "620",
        interfaccia: "Selezione Diretta",
        numselezioni:"20",
        numcontenitori: "9",
        nummixer: "4",
        capPalette: "550",
        gruppoCaffe: "Espresso Z4000",
        numcaldaie: "1",
        protoccolo: "Executive o MDB o BDV",
        altezza: "1830 mm",
        larghezza: "600 mm",
        profondita: "740 mm",
        profonditaPortaAperta: "1250 mm",
        peso: "240 Kg ca.",
        voltaggio: "230-240 V",
        consumo: "",
        frequenza: "50 Hz",
    },
    {
        id: 7,
        nome: "Opera Touch",
        vesione: "Doppio Espresso",
        capacita: "620",
        interfaccia: "Touch screen, 13.3, Full HD",
        numselezioni:"",
        numcontenitori: "",
        nummixer: "",
        capPalette: "",
        gruppoCaffe: "Espresso Z4000",
        numcaldaie: "1",
        protoccolo: "Executive - MDB - BDV",
        altezza: "1830 mm",
        larghezza: "600 mm",
        profondita: "760 mm",
        profonditaPortaAperta: "1250 mm",
        peso: "135 Kg",
        voltaggio: "230 V",
        consumo: "2850 W",
        frequenza: "50 Hz",
    },
    {
        id: 8,
        nome: "Solista",
        vesione: "Espresso",
        capacita: "200",
        interfaccia: "Selezione Diretta",
        numselezioni:"fino a 10",
        numcontenitori: "fino a 6",
        nummixer: "2",
        capPalette: "180",
        gruppoCaffe: "Espresso Z4000",
        numcaldaie: "1",
        protoccolo: "Executive - MDB - BDV",
        altezza: "750 mm - sul mobiletto 1550 mm",
        larghezza: "410 mm",
        profondita: "564 mm",
        profonditaPortaAperta: "822 mm",
        peso: "circa 42 Kg",
        voltaggio: "230 V",
        consumo: "1500 W",
        frequenza: "50 Hz",
    },
    {
        id: 9,
        nome: "Brio Up",
        vesione: "Espresso",
        capacita: "300",
        interfaccia: "Selezione Diretta",
        numselezioni:"fino a 14",
        numcontenitori: "6",
        nummixer: "3",
        capPalette: "255",
        gruppoCaffe: "Espresso Z3000",
        numcaldaie: "1",
        protoccolo: "Executive - MDB - BDV",
        altezza: "760 mm - sul mobiletto 1560 mm",
        larghezza: "540 mm",
        profondita: "605 mm",
        peso: "65 Kg",
        voltaggio: "230 V",
        consumo: "1300 W",
        frequenza: "50 Hz",
    }
]

export default specifiche;