import fasMia from "../macchinette/imagemacchinettecoffee/fasmia.webp";
import fasLydia from "../macchinette/imagemacchinettecoffee/faslydia.webp";
import fasVictoria from "../macchinette/imagemacchinettecoffee/fasvictoria.webp";

const prodottiFas = [
    {
        id: 0,
        nome: "Fas Mia",
        img: fasMia,
        rating: 5
    },
    {
        id: 1,
        nome: "Fas Lydia",
        img: fasLydia,
        rating: 5
    },
    {
        id: 2,
        nome: "Fas Victoria",
        img: fasVictoria,
        rating: 5
    },
]
export default prodottiFas;